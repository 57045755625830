import { Role } from "./roles";

export class RoutingPath {
  public static routes = [
    {
      name: 'Dashboard',
      icon: 'fa fa-tachometer',
      routerLink: '/dashboard',
      access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
      child: [
        [
          {
            name: 'Location Settings',
            routerLink: '/dashboard/location/location-details',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF],
          },
          {
            name: 'Support Tickets',
            routerLink: '/dashboard/support-tickets',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Calendar Feeds',
            routerLink: '/dashboard/feeds',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ],
        // [
        //   {
        //     name: 'Task Dashboard',
        //     routerLink: '/dashboard/task-dashboard',
        //     access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR],
        //   },
        //   {
        //     name: 'Task Calendar',
        //     routerLink: '/dashboard/task-dashboard/task-calendar',
        //     access: [Role.SUPER_ADMIN],
        //   }
        // ],
        [
          {
            name: 'User Activity Logs',
            routerLink: '/dashboard/logs/user-activity',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Email Logs',
            routerLink: '/dashboard/logs/email-reminder',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ],
        [
          {
            name: 'Location Dashboard',
            routerLink: '/dashboard/location',
            access: [Role.SUPER_ADMIN],
          }
        ]
      ]
    },
    {
      name: 'Members',
      icon: 'fa fa-users',
      routerLink: '/members',
      access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
      child: [
        [
          {
            name: 'Member Dashboard',
            routerLink: '/members',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Add Member/User',
            routerLink: '/members/create-member',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Membership Types',
            routerLink: '/members/membership-type-dashboard',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR],
          }
        ],
        [
          {
            name: 'Org/Group/Family Dashboard',
            routerLink: '/organization',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Add Org/Group/Family',
            routerLink: '/organization/add-org',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ],
        [
          {
            name: 'Volunteer Service Dashboard',
            routerLink: '/volunteer',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR],
          }
        ], [
          {
            name: 'Task Dashboard',
            routerLink: '/task-dashboard',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR],
          },
          {
            name: 'Task Calendar',
            routerLink: '/task-dashboard/task-calendar',
            access: [Role.SUPER_ADMIN],
          }
        ],
        [
          {
            name: 'Mailing Lists',
            routerLink: '/email-dashboard',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR],
          }
        ]
      ]
    },
    {
      name: 'Partners',
      icon: 'fa fa-handshake-o',
      routerLink: '/community',
      access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
      child: [
        [
          {
            name: 'Partners Dashboard',
            routerLink: '/community',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Add Partners',
            routerLink: '/community/add-partner',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ],
      ]
    },
    {
      name: 'Reservations',
      icon: 'fa fa-calendar',
      routerLink: '/reservations',
      access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
      child: [
        [
          {
            name: 'Reservation Dashboard',
            routerLink: '/reservations',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Reservation Categories Dashboard',
            routerLink: '/reservations/reservation-categories',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'New Reservation',
            routerLink: '/reservations/create-reservation',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ],
        [
          {
            name: 'Reservation Calendar by Type',
            routerLink: '/reservations/equipment-calendar-by-type',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Reservation Calendar by Group',
            routerLink: '/reservations/equipment-calendar-by-group',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }, {
            name: 'Reservation Calendar by Categories',
            routerLink: '/reservations/reservation-calendar-by-Categories',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ],
      ]
    },
    {
      name: 'Equipment',
      icon: 'fa fa-video-camera',
      routerLink: '/equipment',
      access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
      child: [
        [
          {
            name: 'Equipment Dashboard',
            routerLink: '/equipment',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Add Equipment',
            routerLink: '/equipment/add-equipment',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ],
        [
          {
            name: 'Kit Management',
            routerLink: '/equipment/kit-dashboard',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ],
        [
          {
            name: 'Manage Supplies',
            routerLink: '/equipment/supplies-dashboard',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ],
        [
          {
            name: 'Equipment Class Requirements',
            routerLink: '/equipment/equip/class-requirement',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Equipment Maintenance',
            routerLink: '/equipment/equip/equip-maintenance',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ]
      ]
    },
    {
      name: 'Projects',
      icon: 'fa fa-file-video-o',
      routerLink: '/projects',
      access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
      child: [
        [
          {
            name: 'Project Dashboard',
            routerLink: '/projects',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Add Project',
            routerLink: '/projects/add-project',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ]
      ]
    },
    {
      name: 'Classes',
      icon: 'fa fa-graduation-cap',
      routerLink: '/classes',
      access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
      child: [
        [

          {
            name: 'Class Dashboard',
            routerLink: '/classes',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Add Class',
            routerLink: '/classes/add-class',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ]
      ]
    },
    {
      name: 'Payments',
      icon: 'fa fa-graduation-cap',
      routerLink: '/payments',
      access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
      child: [
        [

          {
            name: 'Payment Dashboard',
            routerLink: '/payments',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Receive Payment',
            routerLink: '/payments/user-payment',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Add New Product Type',
            routerLink: '/payments/user-payment-type',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ]
      ]
    },
    {
      name: 'Fundraising',
      icon: 'fa fa-money',
      routerLink: '/fundraising',
      access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
      child: [
        [
          {
            name: 'Fundraising Dashboard',
            routerLink: '/fundraising',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Campaign Dashboard',
            routerLink: '/fundraising/campaign',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Donation Page Settings',
            routerLink: '/fundraising/donation-settings',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          }
        ],

      ]
    },
    {
      name: 'Reports',
      icon: 'fa fa-pie-chart',
      routerLink: '/reports',
      access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
      child: [
        [
          {
            name: 'Members Report',
            routerLink: '/reports',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Equipment Report',
            routerLink: '/reports/equipment-reports',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Projects Expenditures',
            routerLink: '/reports/projects-reports',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Class Reports',
            routerLink: '/reports/class-reports',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },

          {
            name: 'Fundraising Reports',
            routerLink: '/reports/fundraising-reports',
            access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
          },
          {
            name: 'Login Activity Report',
            routerLink: '/reports/login-reports',
            access: [Role.SUPER_ADMIN],
          },
          {
            name: 'Cron Jobs Report',
            routerLink: '/reports/cron-reports',
            access: [Role.SUPER_ADMIN],
          }
        ],

      ]
    },
    // {
    //   name: 'Isaac Insights',
    //   icon: 'fa fa-repeat',
    //   routerLink: 'issac-insights',
    //   access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
    //   child: []
    // },

    // {
    //   name: 'Forum',
    //   icon: 'fa fa-comments',
    //   routerLink: 'forum',
    //   access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER],
    //   child: []
    // },
    // User Routes
    {
      name: 'Home',
      icon: 'fa fa-home',
      routerLink: '/dashboard',
      access: [Role.USER, Role.CLASSES_ONLY, Role.USER_WITH_ORG],
      child: [[{
        name: 'Support Tickets',
        routerLink: '/dashboard/support-tickets',
        access: [Role.USER, Role.CLASSES_ONLY, Role.USER_WITH_ORG],
      }]]
    },
    {
      name: 'Reservations',
      icon: 'fa fa-calendar',
      routerLink: '/userpanel/my-reservations-dashboard',
      access: [Role.USER, Role.USER_WITH_ORG],
      child: [
        [
          {
            name: 'All My Reservations',
            routerLink: '/userpanel/my-reservations-dashboard',
            access: [Role.USER, Role.USER_WITH_ORG],
          },
          {
            name: 'New Reservation (Pending Availability)',
            routerLink: '/userpanel/my-reservations-dashboard/add-reservation',
            access: [Role.USER, Role.USER_WITH_ORG],
          }
        ], [
          {
            name: 'My Reservations Calendar',
            routerLink: '/userpanel/my-reservations-dashboard/reservations-calendar',
            access: [Role.USER, Role.USER_WITH_ORG],
          },
          {
            name: 'Reservation Calendar by Type',
            routerLink: '/userpanel/my-reservations-dashboard/equipment-calendar-by-type',
            access: [Role.USER, Role.USER_WITH_ORG],
          }
        ]
      ]
    },
    {
      name: 'My Projects',
      icon: 'fa fa-file-video-o',
      routerLink: '/userpanel/my-projects',
      access: [Role.USER, Role.USER_WITH_ORG],
      child: []
    }, {
      name: 'My Organization/Group/Family',
      icon: 'fa fa-file-video-o',
      routerLink: '/userpanel/profile/org-details',
      access: [Role.USER_WITH_ORG],
      child: []
    },
    {
      name: 'My Classes',
      icon: 'fa fa-graduation-cap',
      routerLink: '/userpanel/classes',
      access: [Role.USER, Role.CLASSES_ONLY, Role.USER_WITH_ORG],
      child: [
        [{
          name: 'Registered Classes',
          routerLink: '/userpanel/classes',
          access: [Role.USER, Role.CLASSES_ONLY, Role.USER_WITH_ORG],
        },
        {
          name: 'All Active Classes',
          routerLink: '/userpanel/classes/my-all-classes',
          access: [Role.USER, Role.CLASSES_ONLY, Role.USER_WITH_ORG],
        }]
      ]
    },
    {
      name: 'My Profile',
      icon: 'fa fa-user',
      routerLink: '/userpanel/profile/view-profile',
      access: [Role.USER, Role.CLASSES_ONLY, Role.USER_WITH_ORG],
      child: [
        [
          //   {
          //   name: 'Update Profile',
          //   routerLink: '/userpanel/profile',
          //   access: [Role.USER, Role.CLASSES_ONLY],
          // },
          {
            name: 'View Profile',
            routerLink: '/userpanel/profile/view-profile',
            access: [Role.USER, Role.CLASSES_ONLY, Role.USER_WITH_ORG],
          },
          {
            name: 'Payments',
            routerLink: '/userpanel/profile/enduser-payments',
            access: [Role.USER, Role.CLASSES_ONLY, Role.USER_WITH_ORG],
          },
          // {
          //   name: 'Renew Membership',
          //   routerLink: '/userpanel/profile/renew',
          //   access: [Role.USER],
          // },
          // {
          //   name: 'Profile Details',
          //   routerLink: '/userpanel/profile/renew',
          //   access: [Role.CLASSES_ONLY],
          // }
        ]
      ]
    },
    {
      name: 'Logout',
      icon: 'fa fa-sign-out',
      routerLink: '/login',
      access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER, Role.USER, Role.CLASSES_ONLY, Role.USER_WITH_ORG],
      child: []
    },
  ]
}
