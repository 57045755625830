import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ReservationService } from 'src/app/services/reservation.service';
import * as _moment from 'moment';
import 'moment-timezone';
import { DatePipe, DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from 'src/app/services/location.service';

const moment = _moment

@Component({
  selector: 'app-print-reservation',
  templateUrl: './print-reservation.component.html',
  styleUrls: ['./print-reservation.component.scss']
})
export class PrintReservationComponent implements OnInit {
  viewFilter;
  filterValue = ''
  mode = 'All'
  id;
  loading;
  loadingE;
  loadingS;
  reservationList;
  equipmentList;
  totalSuppliesCost = 0;
  suppliesList;
  locationData;
  locId;
  timezone;
  reservationDetails;
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private datePipe: DatePipe,
    private authService: AuthenticationService,
    private reservationService: ReservationService
  ) {
  }

  ngOnInit(): void {
    this.locationData = this.authService.currentUserValue.location
    this.loading = true
    this.timezone = this.authService.currentUserValue.location.Time_Zone ? this.authService.currentUserValue.location.Time_Zone : "America/New_York"
    this.id = this.route.snapshot.queryParamMap.get('id');
    this.reservationDetail();
    // this.getEquipment();
    // this.getSupplies();
  }

  reservationDetail() {
    this.loading = true
    this.reservationService.getReservationDetails(this.authService.currentUserValue.loc_id, this.id).subscribe(res => {
      let data = []
      data.push(res.data)

      this.titleService.setTitle("Print Reservations Order ID - " + res.data.orderGroupID)
      data = data.map(e => {
        return {
          ...e,
          // startOrderDate: this.datePipe.transform(e.startOrderDate, 'MMMM d, y, h:mm a','UTC'),
          // endOrderDate: this.datePipe.transform(e.endOrderDate, 'MMMM d, y, h:mm a','UTC'),
          startOrderDate: this.getdate(e.startOrderDate),
          endOrderDate: this.getdate(e.endOrderDate),
          addedDate: new Date(new Date(e.addedDate).toLocaleString("en-US", { timeZone: this.timezone }))
        }
      })
      this.reservationDetails = data[0]
      this.getEquipmentSupplies(res.data.asset_grp)
      this.loading = false
    }, err => {
      console.error(err)
      this.loading = false
    })
  }

  getdate(date) {
    let getD = date.indexOf(" ");
    let finDate = date
    if (getD != -1)
      finDate = this.convtDate(date)
    finDate = this.datePipe.transform(finDate, 'MMMM d, y, h:mm a', 'UTC')
    return finDate
  }

  convtDate(givenString) {
    let arrGivenString = [...givenString];
    arrGivenString.splice(10, 1, 'T');
    let output = arrGivenString.join('');
    return output + '.000Z'
  }

  getEquipmentSupplies(data) {
    this.loadingE = true
    let equipData = []
    let suppliesData = []
    data.forEach(element => {
      if (element.eqip_or_supplies == "") {
        equipData.push(element)
      } else if (element.eqip_or_supplies == "Supplies") {
        suppliesData.push(element)
      }
    });
    this.equipmentList = equipData
    this.suppliesList = suppliesData
    this.suppliesCost();
    setTimeout(() => {
      window.print();
    }, 2500);
    this.loadingE = false
  }

  getEquipment() {
    this.loadingE = true
    this.reservationService.getReservationEquip(this.id).subscribe(res => {
      if (res.data != null) {
        const data = res.data.asset
        this.equipmentList = data
        this.loadingE = false
      } else {
        this.loadingE = false
      }
    }, err => {
      this.loadingE = false
      console.error(err)
    })
  }

  getSupplies() {
    this.loadingS = true
    this.reservationService.getReservationSupply(this.id).subscribe(res => {
      const data = res.data
      this.suppliesList = data
      this.suppliesCost();
      this.loadingS = false
      setTimeout(() => {
        window.print();
      }, 2500);
    }, err => {
      this.loadingS = false
      console.error(err)
    })
  }

  suppliesCost() {
    this.suppliesList.forEach(element => {
      this.totalSuppliesCost = this.totalSuppliesCost + parseInt(element.purchase_cost) * parseInt(element.supplies_qty)
      // console.log(this.totalSuppliesCost,element.supplies_qty)
    });
  }

}
