<div class="content">

    <mat-card class="card mat-elevation-z8">
        <h1>
            <div class="fa fa-check-circle"></div>
        </h1>
        <h1>You have Sign up Successfully!</h1>
        <h3 *ngIf="canMemberLogin != 'False' && canMemberLogin != 'org'  ">
            It is a two step process, you have completed first step and to continue further please check your mail and
            follow the Instructions.Thank you!
        </h3>
        <h3 *ngIf="canMemberLogin == 'org' ">
            It is a two step process, you have completed first step and to continue further please check your Primary user mail and
            follow the Instructions.Thank you!
        </h3>
        <h3 *ngIf="(canMemberLogin == 'False' || canMemberLogin == 'false') && classMember == 'class'">
            You have successfully signed up for a class at <b>{{locName}}</b>. <br>
            Here is the class Name <b>{{className}}</b>. <br>
            If you can’t attend this class or to sign up for more classes please call us at <b>{{locPhone}}</b> or email
            us at
            <a href='mailto:{{locEmail}}' style="font-size: 17px"><b>{{locEmail}}</b></a>.<br>
            Thank you, <b>{{locName}}</b>
        </h3>
    </mat-card>

</div>