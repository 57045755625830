import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-member-success-page',
  templateUrl: './member-success-page.component.html',
  styleUrls: ['./member-success-page.component.scss']
})
export class MemberSuccessPageComponent implements OnInit {
  canMemberLogin
  classMember
  className
  locName
  locPhone
  locEmail
  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route
      .queryParams
      .subscribe(params => {
        this.canMemberLogin = params.type
        this.classMember = params.memtype
        this.locName = params.locName
        this.className = params.className
        this.locPhone = params.locPhone
        this.locEmail = params.locEmail
      })
      console.log(this.canMemberLogin)
      console.log(this.classMember)
  }

}
