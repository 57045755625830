<div *ngIf="!loadingf">
    <form *ngIf="access" class="container-fluid " style="padding: 15px;" name="membersForm" autocomplete="off"
        [formGroup]="membersForm" (ngSubmit)="f.form.valid && submit()" #f="ngForm">
        <div class="row">
            <div class="col-6" style="min-height: 50px;">
                <!-- <img src="https://staging.myisaac.com/app/css/logo-isaac.png?v=1611852652" class="img-fluid" /> -->
                <img [src]="locationsList.logo" class="img-fluid logo" />
            </div>
            <div class="col-6 d-flex align-items-sm-end justify-content-sm-end">
                <!-- <img [src]="locationsList.logo" style="max-height: 58px;" class="img-fluid" /> -->
                <a target="_blank" href="http://myisaac.com/"><img src="../../../../assets/isaac_powered_by logo.png"
                        class="img-Isaac" /></a>

            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <mat-card class="card" [style.borderColor]="themeColor">
                    <mat-toolbar class="tool-bar" [style.backgroundColor]="themeColor">
                        <h4> <span class="fa fa-user-plus"></span> Member Information</h4>
                    </mat-toolbar>
                    <div class="container-fluid" style="padding-top: 15px;">
                        <mat-card class="form-card">
                            <h2><span [style.color]="themeColor" class="card-heading"><i [style.color]="themeColor"
                                        class="fa fa-sliders" aria-hidden="true"></i>Choose a Membership Plan</span>
                            </h2>
                            <div *ngIf="membershipFilteredList.length <= 0 "
                                style="text-align: center; font-size: 25px; color: red;">
                                <span> No Membership is available to choose</span>
                            </div>
                            <!-- <div class="card__wrap--outer">
                                <div class="col-sm-12 col-lg-4" *ngFor="let membership of membershipList"
                                    class="card__wrap--inner">
                                    <div class="memberShip-card card">
                                        <div class="card__item card_tit">
                                            <mat-card-header style="margin: auto;text-align: center;">
                                                <mat-card-title>{{membership.membership_type}}</mat-card-title>
                                                <mat-card-subtitle style="font-size: 17px; margin: 10px 0px;">Fee
                                                    :{{membership.member_fee | currency}}/yr.</mat-card-subtitle>
                                            </mat-card-header>
                                        </div>
                                        <hr style="margin-top: 0;margin-left: 15px !important;margin-right: 15px;">

                                        <div class="card__item  flexible">
                                            <b>Description:</b>
                                            <p *ngIf="membership.member_desc !== undefined || null"
                                                [innerHTML]='sanitizer.bypassSecurityTrustHtml(membership.member_desc)'>
                                            </p>

                                        </div>
                                        <hr style="margin-left: 15px !important;margin-right: 15px;margin-bottom: 0;">
                                        <div class="card__footer"> 
<input class="radio" type="radio" [value]="membership"
                                                (change)="updateOnclickGen(membership)" formControlName="member_type">
                                            Select <b>{{membership.membership_type}}</b> Membership Plan
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div *ngIf="membershipFilteredList.length >= 1" class="row member-details">
                                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label class="input-label">Plan<span>*</span></label>
                                    <mat-form-field class="full-width" (click)="reLoad()" appearance="outline">
                                        <mat-select (selectionChange)="updateOnclickGen($event.value)"
                                            [disableOptionCentering]="true" formControlName="member_type">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No Matching Found"
                                                    (keyup)="applyFilterMembership($event.target.value)">
                                                    <mat-icon (click)="reLoad()" ngxMatSelectSearchClear>close
                                                    </mat-icon>
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option matTooltip="{{membership.membership_type}}"
                                                matTooltipClass="customtooltip"
                                                *ngFor="let membership of membershipFilteredList"
                                                [value]="membership.membership_type">
                                                <span
                                                    [ngStyle]="{'color':membership.member_fee==0 ? 'green' : 'red' }">{{membership.membership_type}}
                                                    - Fee: {{membership.member_fee | currency}}</span>
                                            </mat-option>
                                        </mat-select>
                                        <mat-hint *ngIf="!membershipList">loading...</mat-hint>
                                    </mat-form-field>
                                </div>
                                <br>
                                <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8" style="padding: 20px;">
                                    <div class="row" *ngIf="membershipListT && selected">
                                        <div class="col-sm-12 col-md-12 col-lg-6 ">
                                            <b>Title: </b> {{ membershipListT.membership_type}} <b>| Fee: </b><span
                                                [ngStyle]="{'color':membershipListT.member_fee==0 ? 'green' : 'red' }">
                                                {{ membershipListT.member_fee==0?'Free':(membershipListT.member_fee |
                                                currency)}} </span>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-4 ">
                                            <b>User Level: </b>
                                            {{membershipListT.user_level?getRole(membershipListT.user_level):'Member/User'}}
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12 ">
                                            <b>Description: </b>
                                            <p *ngIf="membershipListT.memberdesc !== undefined || null"
                                                [innerHTML]='sanitizer.bypassSecurityTrustHtml(membershipListT.memberdesc)'>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <br>
                            </div>


                        </mat-card>
                        <br>
                        <mat-card *ngIf="selected" class="form-card">
                            <h2><span [style.color]="themeColor" class="card-heading"><i [style.color]="themeColor"
                                        class="fa fa-info-circle" aria-hidden="true"></i>Enter your
                                    Personal Details</span></h2>
                            <div class="row member-details">
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">First Name<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline">
                                        <input matInput formControlName="first_name" required>
                                        <mat-error *ngIf="membersForm.controls['first_name'].errors?.required">First
                                            Name is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Last Name<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline">
                                        <input matInput formControlName="last_name" required>
                                        <mat-error *ngIf="membersForm.controls['last_name'].errors?.required">Last Name
                                            is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="group" class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Organization</label>
                                    <!-- <mat-form-field class="full-width" appearance="outline">
                                    <mat-select [compareWith]="compareOrg" formControlName="organizations">
                                            <input class="selectInput" (keyup)="onKey($event.target.value)">
                                    <mat-option value="">Select organizations</mat-option>
                                    <mat-option *ngFor="let org of organisationListType" [value]="org.value">{{org.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                                    <mat-form-field class="full-width" (click)="reLoad()" appearance="outline" required>
                                        <mat-select [disableOptionCentering]="true" (selectionChange)="selectMT($event)"
                                            [compareWith]="compareOrg" formControlName="organizations" #singleSelect>
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No Matching Found"
                                                    (keyup)="applyFilterOrg($event.target.value)"><mat-icon
                                                        (click)="reLoad()"
                                                        ngxMatSelectSearchClear>close</mat-icon></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option [value]="org.value" *ngFor="let org of organisationfiltered ">
                                                {{org.label}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="membersForm.controls['organizations'].errors?.required">Organization
                                            is
                                            required</mat-error>
                                        <mat-hint style="color: red;"
                                            *ngIf="maxMemberMsg != ''">{{maxMemberMsg}}</mat-hint>

                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Address<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline" required>
                                        <input matInput formControlName="address">
                                        <mat-error *ngIf="membersForm.controls['address'].errors?.required">Address is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">City<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline" required>
                                        <input matInput formControlName="city">
                                        <mat-error *ngIf="membersForm.controls['city'].errors?.required">City is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">State<span>*</span></label>
                                    <mat-form-field class="full-width" (click)="reLoad()" appearance="outline">
                                        <mat-select [disableOptionCentering]="true" formControlName="state">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No Matching Found"
                                                    (keyup)="applyStateFilter($event.target.value)"><mat-icon
                                                        (click)="reLoad()"
                                                        ngxMatSelectSearchClear>close</mat-icon></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let state of stateListfiltered" [value]="state.value">
                                                {{state.label}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Zip Code<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline" required>
                                        <input matInput formControlName="zipcode">
                                        <mat-error *ngIf="membersForm.controls['zipcode'].errors?.pattern">Zipcode is
                                            not valid</mat-error>
                                        <mat-error *ngIf="membersForm.controls['zipcode'].errors?.required">Zip Code is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Primary Phone<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline" required>
                                        <input [textMask]="{mask: mask}" matInput formControlName="tel">
                                        <mat-error *ngIf="membersForm.controls['tel'].errors?.pattern">Primary Phone is
                                            not valid</mat-error>
                                        <mat-error *ngIf="membersForm.controls['tel'].errors?.required">Primary Phone is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Work Phone</label>
                                    <mat-form-field class="full-width" appearance="outline">
                                        <input [textMask]="{mask: mask}" matInput formControlName="work_phone">
                                        <mat-error *ngIf="membersForm.controls['work_phone'].errors?.pattern">Work Phone
                                            is
                                            not valid</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Home Phone</label>
                                    <mat-form-field class="full-width" appearance="outline">
                                        <input [textMask]="{mask: mask}" matInput formControlName="home_phone">
                                        <mat-error *ngIf="membersForm.controls['home_phone'].errors?.pattern">Home Phone
                                            is
                                            not valid</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Email<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline" required>
                                        <input autocomplete="disabled" matInput (focusout)="checkEmail($event)"
                                            formControlName="user_email">
                                        <mat-error *ngIf="membersForm.controls['user_email'].errors?.email">Email
                                            is not valid</mat-error>
                                        <mat-hint *ngIf="checkMail"
                                            [ngStyle]="isEmailValid?{'color':'green'} : {'color': 'red'}">
                                            {{checkMail}}
                                        </mat-hint>
                                    </mat-form-field>
                                </div>
                                <!-- <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                <label class="input-label">Member Photo</label>
                                <input type="file" class="custom-file-input" accept=".png, .jpg" id="customFile"
                                    (change)="uploadImage($event)">
                                <label class="custom-file-label"
                                    for="customFile">{{membersForm.controls.user_photo.value}}</label>
                                <div style="margin-top: 10px;">
                                    <span class="logo-label">Size: Width:500px/Height:323px (JPG and PNG Files Accepted)
                                    </span>
                                </div>
                            </div> -->
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label" style="margin-bottom: 12px;">Under 18?</label>

                                    <mat-radio-group formControlName="user_age_under_18" (change)="radioChange($event)">
                                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                                        <mat-radio-button value="No">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="membersForm.get('user_age_under_18').value == 'Yes'">

                                    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                        <label class="input-label">Parent/Guardian Name</label>
                                        <mat-form-field class="full-width" appearance="outline">
                                            <input matInput formControlName="parent_Name">
                                            <mat-error
                                                *ngIf="membersForm.controls['parent_Name'].errors?.required">Parent/Guardian
                                                Name
                                                is required</mat-error>
                                        </mat-form-field>

                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                        <label class="input-label">Parent Phone</label>
                                        <mat-form-field class="full-width" appearance="outline">
                                            <input [textMask]="{mask: mask}" matInput formControlName="parent_Phone">
                                            <mat-error
                                                *ngIf="membersForm.controls['parent_Phone'].errors?.pattern">Parent
                                                Phone
                                                is
                                                not valid</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                        <label class="input-label">Parent Email</label>
                                        <mat-form-field class="full-width" appearance="outline">
                                            <input matInput formControlName="parent_Email">
                                            <mat-error
                                                *ngIf="membersForm.controls['parent_Email'].errors?.pattern">Parent
                                                Email
                                                is
                                                not valid</mat-error>
                                            <mat-error
                                                *ngIf="membersForm.controls['parent_Email'].errors?.required">Parent
                                                Email
                                                is Required</mat-error>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                                <div class="col-sm-12 text-center" *ngIf="membershipListT.doc.length >= 1">
                                    <!-- <mat-form-field class="full-width" appearance="outline"> -->
                                    <mat-checkbox matInput formControlName="tc" required></mat-checkbox>
                                    <span> I agree to the <a target="_blank" [href]="membershipListT.doc[0].file_name"
                                            style="color: #007bff;">Rules &
                                            Procedures</a> laid out in this document</span>
                                    <mat-error *ngIf="membersForm.controls['tc'].errors?.required">I agree is
                                        required</mat-error>
                                    <!-- </mat-form-field> -->
                                </div>
                            </div>


                        </mat-card>
                        <br>
                        <div *ngIf="selected" class="row">
                            <div class="col-sm-12 text-center">
                                <button [style.backgroundColor]="themeColor" class="primary-button" mat-raised-button>
                                    <span class="fa fa-floppy-o"></span> Sign up
                                </button>
                                <!-- <button class="primary-button ml-2" mat-raised-button>
                                Cancel
                            </button> -->
                            </div>
                        </div>
                        <br>

                    </div>
                </mat-card>

            </div>
        </div>
    </form>

    <div class="notaccess" *ngIf="!access">
        <p align="center"><img src="https://myisaac.com/app/images/access_denied.gif"> <br>
            <font size="+7" color="#D40000"><strong>Your Location is currently Inactive!!</strong></font><br>
        </p>
        <h2 align="center">Please contact the location administrator if you think there has been an error!</h2>
    </div>
</div>
<app-loading *ngIf="loadingf"></app-loading>