<div *ngIf="!loadingf">
    <form *ngIf="access" class="container-fluid" style="padding: 15px;" name="membersForm" autocomplete="off"
        [formGroup]="membersForm" (ngSubmit)="f.form.valid && submit()" #f="ngForm">
        <div class="row">
            <div class="col-6" style="min-height: 50px;">
                <!-- <img src="https://staging.myisaac.com/app/css/logo-isaac.png?v=1611852652" class="img-fluid" /> -->
                <img [src]="locationsList.logo" class="img-fluid logo" />
            </div>
            <div class="col-6 d-flex align-items-sm-end justify-content-sm-end">
                <!-- <img [src]="locationsList.logo" style="max-height: 58px;" class="img-fluid" /> -->
                <a target="_blank" href="http://myisaac.com/"> <img src="../../../../assets/isaac_powered_by logo.png"
                        class="img-Isaac" /></a>

            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <mat-card class="card" [style.borderColor]="themeColor">
                    <mat-toolbar class="tool-bar" [style.backgroundColor]="themeColor">
                        <h4> <span class="fa fa-user-plus"></span> Member Information</h4>
                    </mat-toolbar>
                    <div class="container-fluid" style="padding-top: 15px;">
                        <mat-card class="form-card">
                            <h2><span [style.color]="themeColor" class="card-heading"><i [style.color]="themeColor"
                                        class="fa fa-sliders" aria-hidden="true"></i>Choose a Membership Plan</span>
                            </h2>
                            <div *ngIf="membershipList.length <= 0 "
                                style="text-align: center; font-size: 25px; color: red;">
                                <span> No Membership is available to choose</span>
                            </div>
                            <!-- <div *ngIf="membershipList.length >= 1" class="card__wrap--outer">
                                <div class="col-sm-12 col-lg-4" *ngFor="let membership of membershipList"
                                    class="card__wrap--inner">
                                    <div class="memberShip-card card">
                                        <div class="card__item card_tit">
                                            <mat-card-header style="margin: auto;text-align: center;">
                                                <mat-card-title>{{membership.membership_type}}</mat-card-title>
                                                <mat-card-subtitle style="font-size: 17px; margin: 10px 0px;">Fee
                                                    :{{membership.member_fee | currency}}/yr.</mat-card-subtitle>
                                            </mat-card-header>
                                        </div>
                                        <hr style="margin-top: 0;margin-left: 15px !important;margin-right: 15px;">

                                        <div class="card__item  flexible">
                                            <b>Description:</b>
                                            <p *ngIf="membership.member_desc !== undefined || null"
                                                [innerHTML]='sanitizer.bypassSecurityTrustHtml(membership.member_desc)'>
                                            </p>

                                        </div>
                                        <div class="card__item  flexible">
                                            <b>Maximum Members:</b>
                                            <p *ngIf="membership.max_member_in_grp !== undefined || null">
                                                {{membership.max_member_in_grp }}
                                            </p>

                                        </div>
                                        <hr style="margin-left: 15px !important;margin-right: 15px;margin-bottom: 0;">
                                        <div class="card__footer">
                                            <input class="radio" type="radio" [value]="membership"
                                                (change)="updateOnclickGen(membership)" formControlName="member_type">
                                            Select <b>{{membership.membership_type}}</b> Membership Plan
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div *ngIf="membershipFilteredList.length >= 1" class="row member-details">
                                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label class="input-label">Plan<span>*</span></label>
                                    <mat-form-field class="full-width" (click)="reLoad()" appearance="outline">
                                        <mat-select (selectionChange)="updateOnclickGen($event.value)"
                                            [disableOptionCentering]="true" formControlName="member_type">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No Matching Found"
                                                    (keyup)="applyFilterMembership($event.target.value)">
                                                    <mat-icon (click)="reLoad()" ngxMatSelectSearchClear>close
                                                    </mat-icon>
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option matTooltip="{{membership.membership_type}}"
                                                matTooltipClass="customtooltip"
                                                *ngFor="let membership of membershipFilteredList"
                                                [value]="membership.membership_type">
                                                <span
                                                    [ngStyle]="{'color':membership.member_fee==0 ? 'green' : 'red' }">{{membership.membership_type}}
                                                    - Fee: {{membership.member_fee | currency}}</span>
                                            </mat-option>
                                        </mat-select>
                                        <mat-hint *ngIf="!membershipList">loading...</mat-hint>
                                    </mat-form-field>
                                </div>
                                <br>
                                <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8" style="padding: 20px;">
                                    <div class="row" *ngIf="membershipListT && selected">
                                        <div class="col-sm-12 col-md-12 col-lg-4 ">
                                            <b>Title: </b> {{ membershipListT.membership_type}} <b>| Maximum Members:
                                            </b> {{ membershipListT.max_member_in_grp}} <b>| Fee: </b><span
                                                [ngStyle]="{'color':membershipListT.member_fee==0 ? 'green' : 'red' }">
                                                {{ membershipListT.member_fee==0?'Free':(membershipListT.member_fee |
                                                currency)}} </span>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-4 ">
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12 ">
                                            <b>Description: </b>
                                            <p *ngIf="membershipListT.member_desc !== undefined || null"
                                                [innerHTML]='sanitizer.bypassSecurityTrustHtml(membershipListT.member_desc)'>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <br>
                            </div>


                        </mat-card>
                        <br>
                        <mat-card *ngIf="selected" class="card">
                            <mat-toolbar class="tool-bar">
                                <h4> <span class="fa fa-building"></span> Organization/Group/Family Information</h4>
                            </mat-toolbar>
                            <div *ngIf="!loading" class="container-fluid" style="padding: 15px;">
                                <form name="organizationForm" autocomplete="off" [formGroup]="organizationForm"
                                    (ngSubmit)="f.form.valid && submit()" #f="ngForm">
                                    <mat-card class="form-card">
                                        <span class="card-heading"><i class="fa fa-user-secret"
                                                aria-hidden="true"></i>Organization/Group/Family
                                            Details</span>
                                        <div class="row">

                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                <label class="input-label">Group Name<span>*</span></label>
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <input matInput formControlName="org_name">
                                                    <mat-error
                                                        *ngIf="organizationForm.controls['org_name'].errors?.required">
                                                        Group Name is
                                                        required</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3" id="read-only">
                                                <label class="input-label"># of Members</label>
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <input matInput readonly="true" type="number" min="1"
                                                        formControlName="number_of_members">
                                                    <mat-error
                                                        *ngIf="organizationForm.controls['number_of_members'].errors?.min">
                                                        # of Members cannot be 0
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                <label class="input-label">Address<span>*</span></label>
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <input matInput formControlName="org_address">
                                                    <mat-error
                                                        *ngIf="organizationForm.controls['org_address'].errors?.required">
                                                        Address is
                                                        required</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                <label class="input-label">City<span>*</span></label>
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <input matInput formControlName="org_city">
                                                    <mat-error
                                                        *ngIf="organizationForm.controls['org_city'].errors?.required">
                                                        City is
                                                        required</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                <label class="input-label">State<span>*</span></label>
                                                <mat-form-field class="full-width" (click)="reLoad()"
                                                    appearance="outline">
                                                    <mat-select [disableOptionCentering]="true"
                                                        formControlName="org_state">
                                                        <mat-option>
                                                            <ngx-mat-select-search placeholderLabel="Search State"
                                                                noEntriesFoundLabel="No Matching Found"
                                                                (keydown)="$event.stopPropagation()"
                                                                (keyup)="applyFilter($event.target.value)">
                                                                <mat-icon (click)="reLoad()"
                                                                    ngxMatSelectSearchClear>close</mat-icon>
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option *ngFor="let state of stateListfiltered"
                                                            [value]="state.value">
                                                            {{state.label}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="organizationForm.controls['org_state'].errors?.required">State
                                                        is
                                                        required</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                <label class="input-label">Zip Code<span>*</span></label>
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <input matInput formControlName="org_zip">
                                                    <mat-error
                                                        *ngIf="organizationForm.controls['org_zip'].errors?.pattern">Zipcode
                                                        is
                                                        not valid</mat-error>
                                                    <mat-error
                                                        *ngIf="organizationForm.controls['org_zip'].errors?.required">Zipcode
                                                        is
                                                        required</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                <label class="input-label"> Primary/Cell Phone<span>*</span></label>
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <input [textMask]="{mask: mask}" matInput
                                                        formControlName="org_primary_phone">
                                                    <mat-error
                                                        *ngIf="organizationForm.controls['org_primary_phone'].errors?.pattern">
                                                        Primary/Cell Phone is
                                                        not valid</mat-error>
                                                    <mat-error
                                                        *ngIf="organizationForm.controls['org_primary_phone'].errors?.required">
                                                        Primary/Cell Phone is
                                                        required</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                <label class="input-label">Phone 2</label>
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <input [textMask]="{mask: mask}" matInput
                                                        formControlName="org_phone2">
                                                    <mat-error
                                                        *ngIf="organizationForm.controls['org_phone2'].errors?.pattern">Phone
                                                        2 is
                                                        not valid</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                <label class="input-label">Email<span>*</span></label>
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <input matInput formControlName="org_email">
                                                    <mat-error
                                                        *ngIf="organizationForm.controls['org_email'].errors?.required">Email
                                                        is required</mat-error>
                                                    <mat-error
                                                        *ngIf="organizationForm.controls['org_email'].errors?.email">Email
                                                        is
                                                        not valid</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <span class="card-heading"><i class="fa fa-comment mt-3"
                                                        aria-hidden="true"></i>Notes</span>
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <textarea matInput formControlName="org_notes"></textarea>
                                                    <mat-hint>e.g. Group Notes</mat-hint>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </mat-card>
                                    <br>

                                    <div>
                                        <mat-card class="form-card">
                                            <div>
                                                <div formArrayName="member"
                                                    *ngFor="let c of organizationForm.get('member').controls; let i = index">
                                                    <span class="card-heading"><i class="fa fa-info-circle"
                                                            aria-hidden="true"></i>{{getCardTitle(i)}} Member
                                                        Details</span>
                                                    <div class="row" [formGroupName]="i">
                                                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                            <label class="input-label">First Name<span>*</span></label>
                                                            <mat-form-field class="full-width" appearance="outline">
                                                                <input matInput formControlName="first_name">
                                                                <mat-error
                                                                    *ngIf="c.controls['first_name'].errors?.required">
                                                                    First Name is
                                                                    required</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                            <label class="input-label">Last Name<span>*</span></label>
                                                            <mat-form-field class="full-width" appearance="outline">
                                                                <input matInput formControlName="last_name">
                                                                <mat-error
                                                                    *ngIf="c.controls['last_name'].errors?.required">
                                                                    Last Name is
                                                                    required</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                            <label class="input-label">Address<span>*</span></label>
                                                            <mat-form-field class="full-width" appearance="outline">
                                                                <input matInput formControlName="address"> <mat-error
                                                                    *ngIf="c.controls['address'].errors?.required">
                                                                    Address is
                                                                    required</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                            <label class="input-label">City<span>*</span></label>
                                                            <mat-form-field class="full-width" appearance="outline">
                                                                <input matInput formControlName="city"> <mat-error
                                                                    *ngIf="c.controls['city'].errors?.required">
                                                                    City is
                                                                    required</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                            <label class="input-label">State<span>*</span></label>
                                                            <mat-form-field class="full-width" (click)="reLoad()"
                                                                appearance="outline">
                                                                <mat-select [disableOptionCentering]="true"
                                                                    formControlName="state">
                                                                    <mat-option>
                                                                        <ngx-mat-select-search
                                                                            placeholderLabel="Search State"
                                                                            noEntriesFoundLabel="No Matching Found"
                                                                            (keydown)="$event.stopPropagation()"
                                                                            (keyup)="applyFilter($event.target.value)">
                                                                            <mat-icon (click)="reLoad()"
                                                                                ngxMatSelectSearchClear>close
                                                                            </mat-icon>
                                                                        </ngx-mat-select-search>
                                                                    </mat-option>
                                                                    <mat-option *ngFor="let state of stateListfiltered"
                                                                        [value]="state.value">
                                                                        {{state.label}}
                                                                    </mat-option>
                                                                </mat-select> <mat-error
                                                                    *ngIf="c.controls['state'].errors?.required">
                                                                    State is
                                                                    required</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                            <label class="input-label">Zip Code<span>*</span></label>
                                                            <mat-form-field class="full-width" appearance="outline">
                                                                <input matInput formControlName="zipcode">
                                                                <mat-error
                                                                    *ngIf="c.controls['zipcode'].errors?.required">
                                                                    Zip Code is
                                                                    required</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                            <label class="input-label">Primary
                                                                Phone<span>*</span></label>
                                                            <mat-form-field class="full-width" appearance="outline">
                                                                <input [textMask]="{mask: mask}" matInput
                                                                    formControlName="tel">
                                                                <mat-error *ngIf="c.controls['tel'].errors?.required">
                                                                    Phone No. is
                                                                    required</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                            <label class="input-label">Email<span>*</span></label>
                                                            <mat-form-field class="full-width" appearance="outline">
                                                                <input matInput autocomplete="disabled"
                                                                    (focusout)="checkEmail($event,c.controls['user_email'].errors?.pattern)"
                                                                    formControlName="user_email">
                                                                <mat-error
                                                                    *ngIf="c.controls['user_email'].errors?.required">Email
                                                                    is required</mat-error>
                                                                <mat-error
                                                                    *ngIf="c.controls['user_email'].errors?.pattern">Email
                                                                    is
                                                                    not valid</mat-error>
                                                                <mat-hint *ngIf="checkMail"
                                                                    [ngStyle]="isEmailValid?{'color':'green'} : {'color': 'red'}">
                                                                    {{checkMail}}
                                                                </mat-hint>
                                                            </mat-form-field>
                                                        </div>
                                                        <ng-container *ngIf="c.get('user_age_under_18').value == 'Yes'">
                                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                                <label class="input-label">Under 13?</label>
                                                                <mat-form-field class="full-width" appearance="outline">
                                                                    <mat-select formControlName="user_age_under_13">
                                                                        <mat-option value="Yes">Yes</mat-option>
                                                                        <mat-option value="No">No</mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                                <label class="input-label">Parent/Guardian Name</label>
                                                                <mat-form-field class="full-width" appearance="outline">
                                                                    <input matInput formControlName="parent_Name">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                                <label class="input-label">Parent Phone</label>
                                                                <mat-form-field class="full-width" appearance="outline">
                                                                    <input [textMask]="{mask: mask}" matInput
                                                                        formControlName="parent_Phone">
                                                                    <mat-error
                                                                        *ngIf="c.controls['parent_Phone'].errors?.pattern">Parent
                                                                        Phone is
                                                                        not valid</mat-error>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                                <label class="input-label">Parent Email</label>
                                                                <mat-form-field class="full-width" appearance="outline">
                                                                    <input matInput formControlName="parent_Email">
                                                                    <mat-error
                                                                        *ngIf="c.controls['parent_Email'].errors?.pattern">Parent
                                                                        Email
                                                                        is
                                                                        not valid</mat-error>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                                <label class="input-label">Grade</label>
                                                                <mat-form-field class="full-width" appearance="outline">
                                                                    <input matInput formControlName="Grade">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                                <label class="input-label">Expected Graduation</label>
                                                                <mat-form-field class="full-width" appearance="outline">
                                                                    <input matInput
                                                                        formControlName="expected_Graduation">
                                                                </mat-form-field>
                                                            </div>
                                                        </ng-container>
                                                        <div
                                                            class="col-sm-12 d-flex align-items-sm-center justify-content-sm-end">
                                                            <button type="button" (click)="removeField(i)"
                                                                *ngIf="organizationForm.get('member').controls.length > 1"
                                                                class="primary-button" mat-icon-button>
                                                                <mat-icon>delete</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" *ngIf="isLimit" (click)="addField()"
                                                    mat-raised-button class="primary-button">Add Secondary
                                                    Member</button>
                                            </div>
                                        </mat-card>
                                    </div>
                                    <br>
                                    <!-- <div class="row">
                                            <div class="col-sm-12 text-center">
                                                <button type="submit" mat-raised-button class="primary-button">{{buttonText}}
                                                    Organization</button>
                                                <button style="margin-left: 10px;" mat-button class="secondary-button" mat-raised-button
                                                    [routerLink]="['/organization']">Cancel</button>
                                            </div>
                                        </div> -->

                                    <div class="col-sm-12 text-center" *ngIf="membershipListT.doc.length >= 1">
                                        <!-- <mat-form-field class="full-width" appearance="outline"> -->
                                        <mat-checkbox matInput formControlName="tc" required></mat-checkbox>
                                        <span> I agree to the <a target="_blank"
                                                [href]="membershipListT.doc[0].file_name" style="color: #007bff;">Rules
                                                &
                                                Procedures</a> laid out in this document</span>
                                        <mat-error *ngIf="organizationForm.controls['tc'].errors?.required">I agree is
                                            required</mat-error>
                                        <!-- </mat-form-field> -->
                                    </div>

                                    <br>
                                    <div *ngIf="selected" class="row">
                                        <div class="col-sm-12 text-center">
                                            <button [style.backgroundColor]="themeColor" class="primary-button"
                                                mat-raised-button>
                                                <span class="fa fa-floppy-o"></span> Sign up
                                            </button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <!-- <div *ngIf="loading" style="height:100px;margin-top:30px">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <mat-spinner diameter="40"></mat-spinner>
                                    </div>
                                </div> -->
                        </mat-card>
                        <br>

                        <!-- <div *ngIf="selected" class="row">
                            <div class="col-sm-12 text-center">
                                <button [style.backgroundColor]="themeColor" class="primary-button" mat-raised-button>
                                    <span class="fa fa-floppy-o"></span> Sign up
                                </button>
                            </div>
                        </div> -->
                        <br>

                    </div>
                </mat-card>

            </div>
        </div>
    </form>

    <div class="notaccess" *ngIf="!access">
        <p align="center"><img src="https://myisaac.com/app/images/access_denied.gif"> <br>
            <font size="+7" color="#D40000"><strong>Your Location is currently Inactive!!</strong></font><br>
        </p>
        <h2 align="center">Please contact the location administrator if you think there has been an error!</h2>
    </div>
</div>
<app-loading *ngIf="loadingf"></app-loading>

<!-- {{this.organizationForm.value}}
{{this.organizationForm.valid}} -->