import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fi } from 'date-fns/locale';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  constructor(private http: HttpClient) { }

  getAllReservationsList(size, skip, id, search, filter, mode, sort, sortorder) {
    let url = environment.SERVER_BASEURL + `reservation-management/${id}/reservations?limit=${size}&page=${skip}&sortBy=${sort}&sortOrder=${sortorder}`
    if (search) {
      url = url + `&search=${search}`
    }
    if (mode) {
      url = url + `&mode=${mode}`
    }

    return this.http.get<any>(url);
  }

  // getFilteredReservationList(size,skip,id,filter){
  //   return this.http.post<any>(environment.SERVER_BASEURL + `reservations/search?limit=${size}&page=${skip}&loc_id=${id}`,filter);
  // }

  getFilteredReservationList(size, skip, id, filter, viewFilter, sort, sortorder) {
    return this.http.post<any>(environment.SERVER_BASEURL + `reservation-management/${id}/reservations/search?limit=${size}&page=${skip}&mode=${viewFilter}&sortBy=${sort}&sortOrder=${sortorder}`, filter);
  }

  getReservationDBStatus(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `reservation-management/${loc_id}/reservations/count`);
  }
  getUpcomingReservationsList(size, skip, id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `reservations/upcoming?limit=${size}&page=${skip}&loc_id=${id}`);
  }

  getReservationDetails(locId, reservationid) {
    return this.http.get<any>(environment.SERVER_BASEURL + `reservation-management/${locId}/reservations/${reservationid}`);
  }

  getReservationSupply(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `reservation/supply/${id}`);
  }



  getReservationEquip(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `reservation/equip/${id}`);
  }

  getSuppliesList(locationId) {
    return this.http.get<any>(environment.SERVER_BASEURL + `/equipment-management/${locationId}/supplies?mode=Dropdown`);
  }
  // getProjectList(locationId,userId) {
  //   return this.http.get<any>(environment.SERVER_BASEURL + `project/projdrop-list?loc_id=${locationId}&user_id=${userId}`);
  // }
  getProjectList(locationId, userId) {
    return this.http.get<any>(environment.SERVER_BASEURL + `/project-management/${locationId}/projects?user_id=${userId}&mode=Dropdown`);
  }
  // getEpisodeList(locationId,project_id) {
  //   return this.http.get<any>(environment.SERVER_BASEURL + `project/epidrop-list??loc_id=${locationId}&project_id=${project_id}`);
  // }

  getEpisodeList(locationId, project_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `project-management/${locationId}/projects/${project_id}/episodes?mode=Dropdown`);
  }

  getEquipmentsList(locationId) {
    return this.http.get<any>(environment.SERVER_BASEURL + `equipment-management/${locationId}/equipments?mode=equipment-kits`);
  }

  getKitEquiList(locid, kitno) {
    return this.http.get<any>(environment.SERVER_BASEURL + `equipment-management/${locid}/kits/equipment-tags?tagNumber=${kitno}`)
  }
  // findEquipmentt(payload) {
  //   return this.http.post<any>(environment.SERVER_BASEURL + `reservation/equip`,payload);
  // }
  // addEquipmentsSupplies(payload) {
  //   return this.http.post<any>(environment.SERVER_BASEURL + 'reservations', payload)
  // }
  findEquipmentt(locid, ordergroupid, tagnumber, startdate, enddate) {
    return this.http.get<any>(environment.SERVER_BASEURL + `reservation-management/${locid}/reservations/${ordergroupid}/order-group?startdate=${startdate}&enddate=${enddate}&tagnumber=${tagnumber}`);
  }

  findCheckOutEquipmentt(locid, tagnumber) {
    return this.http.get<any>(environment.SERVER_BASEURL + `reservation-management/${locid}/reservations/${tagnumber}/checkout-Equ`);
  }
  addEquipmentsSupplies(payload, locid, ordergroupid) {
    return this.http.put<any>(environment.SERVER_BASEURL + `reservation-management/${locid}/reservations/${ordergroupid}/order-group`, payload)
  }
  addReservationinfo(payload, locid) {
    return this.http.post<any>(environment.SERVER_BASEURL + `reservation-management/${locid}/reservations`, payload)
  }
  // addReserRecur(payload) {
  //   return this.http.post<any>(environment.SERVER_BASEURL + 'reservation-management/reservationinfo/recur', payload)
  // }

  addReserRecur(payload, locid) {
    return this.http.post<any>(environment.SERVER_BASEURL + `reservation-management/${locid}/reservations?mode=repeat`, payload)
  }

  updateReservationinfo(payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `reservationinfo/${id}`, payload)
  }

  updateReservation(payload, locid, reservation_id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `reservation-management/${locid}/reservations/${reservation_id}`, payload)
  }

  // deleteEqSup(id) {
  //   return this.http.delete<any>(environment.SERVER_BASEURL + `reservations/${id}`)
  // }

  deleteEqSup(ordergroupid, locid, id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `reservation-management/${locid}/reservations/${ordergroupid}/order-group/${id}`)
  }
  putEquipSupplOrderId(payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `reservations/${id}`, payload)
  }

  updateEquipSuppl(payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `reservations/details/${id}`, payload)
  }

  closeReservation(payload, groupId) {
    return this.http.put<any>(environment.SERVER_BASEURL + `reservation/close/${groupId}`, payload)
  }

  approveReservation(payload, groupId, locid) {
    return this.http.put<any>(environment.SERVER_BASEURL + `reservation/approval/${groupId}?loc_id=${locid}`, payload)
  }

  getBusinessHours(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `locations/bizhrs?loc_id=${id}`);
  }

  checkOutReservation(payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + 'reservation/equipment-check-out', payload)
  }

  // checkInReservation(id) {
  //   return this.http.put<any>(environment.SERVER_BASEURL + `reservation/check-in/${id}`,null)
  // }

  checkInReservation(locid, ordergroupid, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `reservation-management/${locid}/reservations/${ordergroupid}/order-group`, payload)
  }

  checkInReservationInfo(id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `reservationinfo/check-in/${id}`, payload)
  }

  getEquipTypeDDL(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `equipment-management/${id}/equipments/type-codes`);
  }

  getEquipGroupDDL(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `equipment-management/${loc_id}/equipments/groups`);
  }

  getEquipTypeReserved(id, type, startDate, endDate) {
    return this.http.get<any>(environment.SERVER_BASEURL + `equipment/calendar-by-type?loc_id=${id}&type=${type}&startDate=${startDate}&endDate=${endDate}`);
  }

  getEquipGroupReserved(locId, type, groupId, date) {
    return this.http.get<any>(environment.SERVER_BASEURL + `reservation-management/${locId}/reservations/calendar?type=${type}&group=${groupId}&date=${date}`);
  }

  getEquipTypeCal(equipType, loc_id, startDate, endDate) {
    return this.http.get<any>(environment.SERVER_BASEURL + `equipment-management/${loc_id}/equipments/${equipType}/reservations?startDate=${startDate}&endDate=${endDate}`)
  }

  getCatCal(catId, loc_id, startDate, endDate) {
    return this.http.get<any>(environment.SERVER_BASEURL + `reservation-management/${loc_id}/reservations/${catId}/cat-calendar?startDate=${startDate}&endDate=${endDate}`)
  }


  getModalsList(loc_id, type, equipmentGroup) {
    let url = environment.SERVER_BASEURL + `equipment-management/${loc_id}/equipments/${type}/modals`
    if (equipmentGroup) {
      url = url + `?equipmentGroup=${equipmentGroup}`
    }
    return this.http.get<any>(url);
  }

  getMergeReservation(id, groupId) {
    return this.http.get<any>(environment.SERVER_BASEURL + `reservations/active?user_id=${id}&orderGroupID=${groupId}`);
  }
  // postMergeReservation(payload) {
  //   return this.http.post<any>(environment.SERVER_BASEURL + `reservation/merge`,payload);  
  // }

  postMergeReservation(currentid, mergeid, payload, locId) {
    return this.http.put<any>(environment.SERVER_BASEURL + `reservation-management/${locId}/reservations/${currentid}/order-group/${mergeid}`, payload);
  }

  // userpanel
  getAllUserReservationsForDashboard(size, skip, id, search, userID, mode) {
    let url = environment.SERVER_BASEURL + `reservations/user-dashboard?user_id=${userID}&limit=${size}&page=${skip}&loc_id=${id}`
    if (search) {
      url = url + `&search=${search}`
    }
    if (mode) {
      url = url + `&mode=${mode}`
    }
    return this.http.get<any>(url);
  }

  // getAllUserReservationsList(size, skip,id, search, userID,mode ) {
  //   let url = environment.SERVER_BASEURL + `reservation-management/reservations/user?user_id=${userID}&limit=${size}&page=${skip}&loc_id=${id}`
  //   if (search) {
  //     url = url + `&search=${search}`
  //   }
  //   if (mode) {
  //     url = url + `&mode=${mode}`
  //   }
  //   return this.http.get<any>(url);
  // }

  getAllUserReservationsList(size, skip, id, search, userID, mode) {
    let url = environment.SERVER_BASEURL + `reservation-management/reservations/user?user_id=${userID}&limit=${size}&page=${skip}&loc_id=${id}`
    if (search) {
      url = url + `&search=${search}`
    }
    if (mode) {
      url = url + `&mode=${mode}`
    }
    return this.http.get<any>(url);
  }

  // getUserReservationsCalander(uId,id,startDate,endDate) {
  //   let url = environment.SERVER_BASEURL + `reservations/user-calander?user_id=${uId}&loc_id=${id}&startdate=${startDate}&enddate=${endDate}`
  //   return this.http.get<any>(url);
  // }

  getUserReservationsCalander(uId, id, startDate, endDate) {
    let url = environment.SERVER_BASEURL + `reservation-management/reservations/user-calander?user_id=${uId}&loc_id=${id}&startdate=${startDate}&enddate=${endDate}`
    return this.http.get<any>(url);
  }

  changeRevPendingStatus(id, mode) {
    return this.http.get<any>(environment.SERVER_BASEURL + `reservation-management/reservations/status?loc_id=${id}&mode=${mode}`);
  }

  RevPendingStatus(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `reservation-management/reservations/status?loc_id=${id}&mode=All`);
  }

  sendReservationEmail(id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `reservation-management/${id}/reservation/mail`, payload);
  }

  // Reservations Categories

  getAllCategories(page, locationId, size, search) {
    let url = environment.SERVER_BASEURL + `reservation-management/${locationId}/categories?page=${page}&limit=${size}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getCategoriesDD(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `reservation-management/${loc_id}/categories/dropdown`)
  }

  createCategories(payload, loc_id) {
    return this.http.post<any>(environment.SERVER_BASEURL + `reservation-management/${loc_id}/categories`, payload)
  }

  updateCategories(id, payload, loc_id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `reservation-management/${loc_id}/categories/${id}`, payload)
  }

  deleteCategories(id, loc_id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `reservation-management/${loc_id}/categories/${id}`);
  }

}
