<form *ngIf="!loading" [style.--dynamic-primary-color]="buttonStyles" name="creditCardFormForSquare" autocomplete="off"
  [formGroup]="creditCardFormForSquare" #cf="ngForm" class="container-fluid">
  <mat-card class="form-card">
    <span class="card-heading"><i class="fa fa-credit-card" aria-hidden="true"></i>Credit Card</span>
    <br>
    <div class="row">
      <div class="col-sm-12">
        <br>
        <label class="input-label">Card Holder Name</label>
        <mat-form-field class="full-width" appearance="outline">
          <input formControlName="name" matInput placeholder="Card Holder Name" >
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <label style="margin-bottom: 10px" class="input-label">Credit Card Details<span>*</span></label>
        <!-- <div id="card-container"></div>
         <div id="sq-card-number"></div>
        <div class="third" id="sq-expiration-date"></div>
        <div class="third" id="sq-cvv"></div>
        <div class="third" id="sq-postal-code"></div> -->
        <iframe #myIframe [src]="safeIframeSrc" (load)="onIframeLoad()" frameborder="0"></iframe>
      </div>

      <div class="col-sm-12">
        <br>
        <label class="input-label">Notes</label>
        <mat-form-field class="full-width" appearance="outline">
          <textarea formControlName="Notes" rows="4" matInput placeholder="Notes"></textarea>
          <mat-hint>e.g Payment Notes.</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <!-- <br>
  <div class="row">
    <div class="col-sm-12">
      <button id="sq-creditcard" (click)="pay()" class="button-credit-card primary-button" type="submit"
        mat-raised-button>Pay
        Now</button>
    </div>
  </div> -->
</form>
<!-- <div class="col-sm-12">
  <label style="margin-bottom: 10px" class="input-label">Credit Card Details<span>*</span></label>
  <iframe #myIframe [src]="safeIframeSrc" frameborder="0" ></iframe>
</div> -->

<app-loading *ngIf="loading"></app-loading>