<div class="container-fluid">
    <!-- <div class="row">
        <div class="offset-md-3 col-md-6 col-sm-12 col-xs-12 login"> -->
    <div style="text-align: center;">
        <div class="login">
            <div class=" login">
                <mat-card class="card-style">
                    <mat-toolbar class="tool-bar">
                        <h4>Please Sign In</h4>
                    </mat-toolbar>
                    <div class="row">
                        <!-- <div class="col-sm-12 text-center">
                        <img class="logo-image" src="../../../../assets/logo-isaac.png" />
                    </div> -->
                        <div class="col-sm-12">
                            <form class="loginForm" name="loginForm" [formGroup]="loginForm"
                                (ngSubmit)="f.form.valid && login()" #f="ngForm">
                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <label class="input-label">Username or Email<span>*</span></label>
                                        <mat-form-field class="full-width" appearance="outline">
                                            <input matInput placeholder="Username or Email" formControlName="username"
                                                required>
                                            <mat-icon class="icon" matSuffix>account_circle</mat-icon>
                                            <mat-error *ngIf="loginForm.controls['username'].invalid">Username or Email
                                                is
                                                required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="input-label">Password<span>*</span></label>
                                        <mat-form-field class="full-width" appearance="outline">
                                            <input type="password" matInput placeholder="Password"
                                                formControlName="password" required>
                                            <mat-icon class="icon" matSuffix>vpn_key</mat-icon>
                                            <mat-error *ngIf="loginForm.controls['username'].invalid">Password is
                                                required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col-sm-12">
                                    <mat-checkbox formControlName="rememberMe" class="full-width check-remember">Remember Me!</mat-checkbox>
                                </div> -->
                                    <div class="col-sm-12">
                                        <!-- <br /> -->
                                        <button type="submit" [disabled]="loginForm.invalid"
                                            class="btn btn-block loginbtn" mat-raised-button><i
                                                class="fa fa-sign-in"></i> Login</button>
                                    </div>
                                    <div class="col-sm-12">
                                        <br />
                                        <mat-divider></mat-divider>
                                    </div>

                                </div>
                            </form>
                            <div class="col-sm-12" style="padding-right: 0;">
                                <!-- <br /> -->
                                <a class="forgotBtn" [routerLink]="['forgot-password']">Forgot
                                    Password?</a>
                                <a target="_blank" href="http://myisaac.com/"><img class="logo-image"
                                        src="../../../../assets/isaac_powered_by logo.png" /></a>

                            </div>


                        </div>

                    </div>
                </mat-card>

            </div>
        </div>
    </div>
    <app-loading *ngIf="isLoading"></app-loading>