<div class="content">
    <p *ngIf="userInactive">
        <!-- <img src="../../../../assets/access_denied.gif"><br> -->
        <b><span style="color: red;">OH NO! IT LOOKS LIKE YOUR MEMBERSHIP HAS EXPIRED.</span> </b><br> 
       <span style="font-size: smaller;font-weight: 500;"> YOUR SUPPORT AND PARTICIPATION IS IMPORTANT TO US.<br>
        TO RENEW YOUR MEMBERSHIP WITH <b>{{currentUser.location.name | uppercase}}</b>  PLEASE CONTACT US AT {{currentUser.location.phone}} OR <a href='mailto:{{currentUser.location.email}}'>{{currentUser.location.email}}</a> </span> 
    </p>
    <p *ngIf="locInactive">
        <!-- <img src="../../../../assets/access_denied.gif"><br> -->
        <b><span style="color: red;">Your Location is currently Inactive!!</span> </b><br> 
       <span style="font-size: smaller;font-weight: 500;">Please contact the location administrator if you think there has been an error!</span> 
    </p>
    <p *ngIf="usernotallowed">
        <!-- <img src="../../../../assets/access_denied.gif"><br> -->
        <b><span style="color: red;">Your Location is currently Not allowing Member to Login!!</span> </b><br> 
       <span style="font-size: smaller;font-weight: 500;">Please contact the location administrator if you think there has been an error!</span> 
    </p>
</div>