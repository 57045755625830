<form name="creditCardFormForPaypal" [style.--dynamic-primary-color]="buttonStyles" autocomplete="off" [formGroup]="creditCardFormForPaypal"
    (ngSubmit)="cf1.form.valid && payForCreditCardWithPaypal()" #cf1="ngForm" class="container-fluid">
    <mat-card class="form-card">
        <span class="card-heading"><i class="fa fa-credit-card" aria-hidden="true"></i>Credit Card</span>
        <br>
        <div class="row">
            <div class="col-sm-6">
                <label class="input-label">Card Holder Name<span>*</span></label>
                <mat-form-field class="full-width" appearance="outline">
                    <input matInput formControlName="cardholdername" placeholder="Card Holder Name">
                    <mat-error *ngIf="creditCardFormForPaypal.controls['cardholdername'].errors?.required">
                        Card Holder Name is
                        required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <label class="input-label">Card Number<span>*</span></label>
                <mat-form-field class="full-width" appearance="outline">
                    <input (keypress)="validateCardNumber($event)" formControlName="credit_card_number" matInput
                        placeholder="Card Number" required>
                    <mat-error *ngIf="creditCardFormForPaypal.controls['credit_card_number'].errors?.required">
                        Card Number
                        is
                        required</mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-4">
                <label class="input-label">Expires (Month)<span>*</span></label>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-select formControlName="card_exp_month">
                        <mat-option *ngFor="let month of months" [value]="month.value">{{month.label}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="creditCardFormForPaypal.controls['card_exp_month'].errors?.required">
                        Expires (Month)
                        is
                        required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <label class="input-label">Expires (Year)<span>*</span></label>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-select formControlName="card_exp_yr">
                        <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="creditCardFormForPaypal.controls['card_exp_yr'].errors?.required">Expires
                        (Year)
                        is
                        required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <label class="input-label">CVV<span>*</span></label>
                <mat-form-field class="full-width" appearance="outline">
                    <input (keypress)="validateCode($event)" formControlName="card_security_code" size="3" matInput
                        placeholder="CVV" required>
                    <mat-error *ngIf="creditCardFormForPaypal.controls['card_security_code'].errors?.required">
                        Secutiry Code
                        is
                        required</mat-error>
                </mat-form-field>
            </div>
        </div>
    </mat-card>
    <br>
    <div class="row">
        <div class="col-sm-12">
            <button type="submit" class="primary-button" mat-raised-button>Pay Now</button>
        </div>
    </div>
</form>
<app-loading *ngIf="loading"></app-loading>
