<!-- <h3  *ngIf="!paymentDone" style="margin: auto 15px;"><span class="card-heading"><i class="fa fa-credit-card"
  aria-hidden="true"></i>Public Fundraising URL :</span> </h3>
 -->

<br>
<div style="margin: .5px;" class="row" *ngIf="infoD">
  <div *ngIf="!paymentDone" class="col-lg-12">

    <form name="donationForm" (ngSubmit)="f.form.valid && submit()" #f="ngForm" [formGroup]="donationForm">
      <mat-card [ngStyle]="currentStyles" class="container-fluid mat-elevation-z3 coloMat">
        <br />
        <div *ngIf="!showPayment" class="row">
          <div class="col-sm-12">
            <label [ngStyle]="inputStyles" class="input-label">Campaign:<span>*</span></label>
            <mat-form-field class="full-width" appearance="outline">
              <mat-select [disabled]="campaignslist.length <= 0" required formControlName="d_campaign">
                <mat-option *ngFor="let campaigns of campaignslist" [value]="campaigns._id">
                  {{campaigns.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="donationForm.controls['d_campaign'].errors?.required">Campaign Name is required
              </mat-error>
              <mat-hint style="color: red;font-size: 15px;" *ngIf="campaignslist.length <= 0 ">No Campaign
                available</mat-hint>

            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <label [ngStyle]="inputStyles" class="input-label">First Name:<span>*</span></label>
            <mat-form-field class="full-width" appearance="outline">
              <input required formControlName="d_first_name" matInput>
              <mat-error *ngIf="donationForm.controls['d_first_name'].errors?.required">First Name is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <label [ngStyle]="inputStyles" class="input-label">Last Name:<span>*</span></label>
            <mat-form-field class="full-width" appearance="outline">
              <input required formControlName="d_last_name" matInput>
              <mat-error *ngIf="donationForm.controls['d_last_name'].errors?.required">Last Name is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <label [ngStyle]="inputStyles" class="input-label">Email:<span>*</span></label>
            <mat-form-field class="full-width" appearance="outline">
              <input type="email" autocomplete="disabled" required formControlName="d_user_email" matInput>
              <mat-error *ngIf="donationForm.controls['d_user_email'].errors?.required">Email is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <label [ngStyle]="inputStyles" class="input-label">Phone:</label>
            <mat-form-field class="full-width" appearance="outline">
              <input type="text" [textMask]="{mask: mask}" formControlName="d_tel" matInput>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <label [ngStyle]="inputStyles" class="input-label">Address:</label>
            <mat-form-field class="full-width" appearance="outline">
              <input formControlName="d_address" matInput>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <label [ngStyle]="inputStyles" class="input-label">Organization:</label>
            <mat-form-field class="full-width" appearance="outline">
              <input formControlName="d_organization" matInput>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <label [ngStyle]="inputStyles" class="input-label">Your donation amount</label>
            <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" required
              (change)="onChange($event)" formControlName="d_amount">
              <mat-radio-button [ngStyle]="inputStyles" class="radio-button" *ngFor="let amount of amounts"
                [value]="amount">
                <span *ngIf="amount!=='Other'">$</span>{{amount}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-sm-12" *ngIf="showOther">
            <mat-form-field class="full-width" appearance="outline">
              <input formControlName="d_amount" required name="amountS" matInput>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <br>
            <button [ngStyle]="buttonStyles" mat-button class="primary-button sButton" type="submit"
              mat-raised-button>Submit</button>
          </div>
        </div>
        <div class="row">
          <div *ngIf="showPayment" class="col-sm-12">
            <br>
            <mat-card>
              <p>
                <b>Member Name:</b> {{getName()}} <b>Amount</b>: {{getAmount() | currency}} <b> Payment
                  For</b>: {{getCampaignName()}} Donation
              </p>
            </mat-card>
            <br>
            <stripe-card *ngIf="paymentGateway === 'Stripe'" [buttonStyles]='buttonStyles' [name]="getName()"
              [loc_id]="getLocId()" [userId]="getCampaignId()" [paymentDesc]="getPaymentDesc()"
              [itemDetails]='getListItems()' [grandTotal]='getAmount()' (paymentResponse)="onPaymentResponse($event)">
            </stripe-card>
            <paypal-card *ngIf="paymentGateway == 'PayPal'" [buttonStyles]='buttonStyles' [loc_id]="getLocId()"
              [userId]="getCampaignId()" [paymentDesc]="getPaymentDesc()" [itemDetails]='getListItems()'
              [grandTotal]='getAmount()' (paymentResponse)="onPaymentResponse($event)">
            </paypal-card>
            <square-card *ngIf="paymentGateway === 'Square'" [buttonStyles]='buttonStyles' [loc_id]="getLocId()"
              [name]="getName()" [userId]="getCampaignId()" [itemDetails]='getSquareItems()' [grandTotal]='grandTotal'
              (paymentResponse)="onPaymentResponse($event)">
            </square-card>
            <div style="color: red;" *ngIf="!paymentGateway">
              <h4>No Credit Card Payment option is available at the moment. Please contact your Location/Station.</h4>
            </div>
          </div>
        </div>



      </mat-card>

    </form>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>