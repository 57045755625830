import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  getReports(url,payload) {
    // let finalUrl = environment.SERVER_BASEURL + url + page + '&limit=' + limit + '&loc_id='
    // if (search)
    //   finalUrl = finalUrl + '&search=' + search
    return this.http.post<any>(environment.SERVER_BASEURL + url, payload);
  }
  getCatReports(url) {
    // let finalUrl = environment.SERVER_BASEURL + url + page + '&limit=' + limit + '&loc_id='
    // if (search)
    //   finalUrl = finalUrl + '&search=' + search
    return this.http.get<any>(environment.SERVER_BASEURL + url);
  }
  
  // getCronJobReports(loc_id, page, limit) {
  //   return this.http.get<any>(environment.SERVER_BASEURL + `cronreport?loc_id=${loc_id}&page=${page}&limit=${limit}`);
  // }

  getCronJobReports(loc_id, page, limit) {
    return this.http.get<any>(environment.SERVER_BASEURL + `location-management/${loc_id}/cron-logs?page=${page}&limit=${limit}`);
  }

  getArchivedReports(payload){
    return this.http.post<any>(environment.SERVER_BASEURL + 'report/archive', payload);
  }

}
