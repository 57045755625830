import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocationService } from 'src/app/services/location.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SquarePaymentService } from 'src/app/services/square-payment.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { MemberService } from 'src/app/services/member.service';
declare var SqPaymentForm: any;
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import moment from 'moment';

class squareGW {
  gateway_signature: string
  gateway_api: string
}
@Component({
  selector: 'square-card',
  templateUrl: './square-payment.component.html',
  styleUrls: ['./square-payment.component.scss']
})
export class SquarePaymentComponent implements OnInit {
  @ViewChild('myIframe') iframeRef: ElementRef;

  @Input() userId
  @Input() loc_id
  @Input() buttonStyles
  @Input() name
  @Input() grandTotal
  @Input() itemDetails
  @Input() userOId
  @Input() payment_desc
  @Input() can_member_login
  // Output Variables
  @Output() paymentResponse = new EventEmitter<any>()
  showLoader = true;
  updatedDate = moment().add(1, 'years').format('YYYY-MM-DD')
  url
  ppayload
  creditCardFormForSquare: FormGroup
  loading: boolean;
  squareToken
  paymentForm: any;
  iframeSrc: string
  safeIframeSrc: SafeResourceUrl;
  constructor(private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private memberService: MemberService,
    private paymentService: PaymentService,
    private locationService: LocationService,
    private squarePaymentService: SquarePaymentService,
    private sanitizer: DomSanitizer
  ) {
    this.loading = true
    setTimeout(() => {
      // this.iframeSrc = environment.SERVER_BASEURL + `squarepay?loc_id=${this.loc_id}&userID=${this.userId}&price=${this.grandTotal}&dateExpires=${this.updatedDate}&userOID=${this.userOId}&canMemberLogin=${this.can_member_login}&payment_desc=${this.payment_desc}`
      this.iframeSrc = environment.SERVER_BASEURL + `squarepay?loc_id=${this.loc_id}`
      this.safeIframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);
      this.loading = false
      console.log(JSON.stringify(this.safeIframeSrc))
    }, 3000);

  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    console.log(event)
    if (event.source === this.iframeRef.nativeElement.contentWindow) {
      const messageData = event.data;
      // console.log(messageData)
      this.squareToken = messageData.token
      this.pay()
    }
  }


  async ngOnInit() {
    this.loading = true
    this.creditCardFormForSquare = this.formBuilder.group({
      payment_method: "",
      Notes: '',
      name: ''
    });
    this.loading = false
  }

  onIframeLoad() {
    // This function will be called when the iframe finishes loading
    this.showLoader = false;
  }
  payForCreditCardWithSquare() {
    this.paymentForm.requestCardNonce();
  }

  pay() {
    this.loading = true
    let payload = {
      source_id: this.squareToken,
      itemDetails: this.itemDetails,
      payment_desc: this.itemDetails.payment_desc,
      loc_id: this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id,
    }
    if (this.itemDetails.purchaseItems) {
      this.itemDetails.purchaseItems[0].total = this.itemDetails.purchaseItems[0].total * 100
      if (this.itemDetails.purchaseItems[0]['key']) {
        payload.itemDetails.purchaseItems[0]['campaignId'] = this.userId
        payload = {
          ...payload,
          ...payload.itemDetails.purchaseItems[0]['donationData']
        }
        delete payload.itemDetails.purchaseItems[0]['key']
        delete payload.itemDetails.purchaseItems[0]['donationData']
      } else {
        payload['userId'] = this.userId
      }
    } else {
      payload['userId'] = this.userId
    }
    // console.log('this',payload)
    // if (this.itemDetails.purchaseItems[0].campaignId) {
    //   this.paymentService.paySquareDonation(this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id, payload).subscribe(res => {
    //     // this.paymentService.payStripe(payload).subscribe(res => {
    //     if (res) {
    //       this.toastr.success(res.message);
    //       this.paymentResponse.emit({ status: true, response: res.data })
    //       this.loading = false
    //     }
    //   }, err => {
    //     this.loading = false
    //     this.toastr.error(err.error.message);
    //     console.log(err)
    //   })
    // } else {
    this.paymentService.paySquare(this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id, payload).subscribe(res => {
      // this.paymentService.payStripe(payload).subscribe(res => {
      if (res) {
        this.toastr.success(res.message);
        this.paymentResponse.emit({ status: true, response: res.data })
        this.loading = false
      }
    }, err => {
      this.loading = false
      this.toastr.error(err.error.message);
      console.log(err)
    })
    // }
  }

}


