<form *ngIf="!loading" class="container-fluid" name="membersPaymentForm" autocomplete="off"
    [formGroup]="membersPaymentForm" (ngSubmit)="f.form.valid && submit()" #f="ngForm">
    <!-- <div class="row">
        <div class="col-6">
            <img src="https://staging.myisaac.com/app/css/logo-isaac.png?v=1611852652" class="img-fluid" />
        </div>
        <div class="col-6 d-flex align-items-sm-center justify-content-sm-end">
            <img style="height: 58px; padding-right: 15px;" [src]="locationsList.logo" class="img-fluid" />
        </div>
    </div> -->
    <div class="container-fluid">
        <mat-card class="card" [style.borderColor]="themeColor">
            <mat-toolbar class="tool-bar" [style.backgroundColor]="themeColor">
                <h4> <span class="fa fa-money"></span> Payment for Membership</h4>
            </mat-toolbar>
            <div *ngIf="memberData != undefined && !memberData.organizations" class="container-fluid">
                <mat-card class="form-card">
                    <b>Member Name:</b> {{memberData.first_name + ' ' + memberData.last_name}} <b>Amount:</b>
                    {{memberShipFee | currency}} <b>Payment For:</b> {{data.classData?data.classData.title:'Member
                    Signup'}}
                </mat-card>
            </div>
            <div *ngIf="memberData.organizations" class="container-fluid">
                <mat-card class="form-card">
                    <b>Organization Name:</b> {{memberData.orgname }} - <b>Primary Member Name:</b>
                    {{memberData.first_name + ' ' + memberData.last_name}} <b>Amount:</b>
                    {{memberShipFee | currency}} <b>Payment For:</b> Organization Signup
                </mat-card>
            </div>
            <div *ngIf='memberData == undefined ' class="container-fluid">
                <mat-card class="form-card">
                    <b>Sorry Data lost Please Try Again</b>
                </mat-card>
            </div>
            <div class="container-fluid">
                <mat-card class="card" style="border-color: #f5f5f5;">
                    <mat-toolbar style="background-color: #f5f5f5;" class="tool-bar">
                        <h4 style="color: black;"> <span style="color: black !important;"
                                class="fa fa-credit-card"></span> Payment for Membership</h4>
                    </mat-toolbar>

                    <div class="container-fluid">
                        <!-- <div class="row"> -->
                        <div>
                            <stripe-card *ngIf="paymentGateway === 'Stripe'" [buttonStyles]='themeColor '
                                [name]="memberData.full_name" [userId]="userid" [loc_id]="getLocId()"
                                [itemDetails]='getListItems()' [grandTotal]='grandTotal'
                                (paymentResponse)="onPaymentResponse($event)">
                            </stripe-card>
                            <paypal-card *ngIf="paymentGateway == 'PayPal'" [buttonStyles]='themeColor '
                                [loc_id]="getLocId()" [userId]="userid" [itemDetails]='getListItems()'
                                [paymentDesc]="getPaymentDesc()" [grandTotal]='grandTotal'
                                (paymentResponse)="onPaymentResponse($event)">
                            </paypal-card>
                            <square-card *ngIf="paymentGateway === 'Square'" [buttonStyles]='themeColor '
                                [name]="data.userData.user_name" [loc_id]='data.data.loc_id' [userId]="userid"
                                [userOId]="data.userData._id" [itemDetails]='getListItems()' [grandTotal]='grandTotal'
                                [payment_desc]="paymentdesc" [can_member_login]="locationsList.can_member_login "
                                (paymentResponse)="onPaymentResponse($event)">
                            </square-card>
                            <div *ngIf="!paymentGateway">
                                <h4>No Credit Card Payment option is available at the moment. Please contact your
                                    Location/Station.
                                </h4>
                            </div>
                            <!-- <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                <label class="input-label">Card Holder Name<span>*</span></label>
                                <mat-form-field class="full-width" appearance="outline">
                                    <input matInput  formControlName="user_name" required>
                                    <mat-error *ngIf="membersPaymentForm.controls['user_name'].errors?.required">Card Holder Name is
                                        required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                <label class="input-label">Card Number<span>*</span></label>
                                <mat-form-field class="full-width" appearance="outline">
                                    <input matInput type="number" min="0"   formControlName="card_number" required>
                                    <mat-error *ngIf="membersPaymentForm.controls['card_number'].errors?.required">Card Number is
                                        required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                <label class="input-label">Card Type<span>*</span></label>
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-select formControlName="card_type" required>
                                    <mat-option value="">Select Card</mat-option>
                                    <mat-option *ngFor='let cardType of cardTypes' value="cardType">{{cardType}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="membersPaymentForm.controls['card_type'].errors?.required">Card Type is
                                        required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                <label class="input-label">Security Code<span>*</span></label>
                                <mat-form-field class="full-width" appearance="outline">
                                    <input matInput placeholder="xxx" formControlName="security_code" required>
                                    <mat-error *ngIf="membersPaymentForm.controls['security_code'].errors?.required">Security Code is
                                        required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                <label class="input-label">Expires (Month)<span>*</span></label>
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-select formControlName="month" required>
                                    <mat-option value="">Month</mat-option>
                                    <mat-option *ngFor='let month of months' value="month">{{month}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="membersPaymentForm.controls['month'].errors?.required">Expires (Month is
                                        required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                <label class="input-label">Expires (Year)<span>*</span></label>
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-select formControlName="year" required>
                                    <mat-option value="">Year</mat-option>
                                    <mat-option *ngFor='let year of years' value="year">{{year}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="membersPaymentForm.controls['year'].errors?.required">Year is
                                        required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12 ">
                                <label class="input-label">Notes</label>
                                <mat-form-field class="full-width" appearance="outline">
                                    <textarea matInput   formControlName="note" ></textarea> 
                                </mat-form-field>
                            </div> -->

                        </div>
                    </div>
                </mat-card>

            </div>
            <br>
            <div class="row">
                <div class="col-sm-12 text-center">
                    <!-- <button (click)="submit()" class="primary-button"mat-raised-button>
                                Pay Now
                            </button> -->
                    <button [style.backgroundColor]="themeColor" (click)="cancel()" class="primary-button ml-2"
                        mat-raised-button>
                        Cancel (Please don't cancel if payment is processing)
                    </button>

                </div>
            </div>
            <br>
        </mat-card>

    </div>
</form>