import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  private secretKey: string = 'myIsaac3.0'; // Use a secure key in production

  constructor() { }

  // Method to encrypt data
  encryptData(data: string): string {
    const encrypted = CryptoJS.AES.encrypt(data, this.secretKey).toString();
    return encrypted;
  }

  // Method to decrypt data
  decryptData(encryptedData: string): string {
    const bytes = CryptoJS.AES.decrypt(encryptedData, this.secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  }
}
