export enum Role {
    SUPER_ADMIN = 5,
    LOCATION_ADMIN = 4,
    LOCATION_STAFF = 3.5,
    INSTRUCTOR = 3,
    COMMUNITY_PARTNER = 1.5,
    USER = 1,
    USER_WITH_ORG = 1.1,
    CLASSES_ONLY = 0.5,
    Associate = 0.1
}