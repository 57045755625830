<div *ngIf="!loading" style="background: #999;">
    <body >
        <header>
        <h1>Receipt</h1>
        <address>
          <p><b>{{locationData.name | titlecase}}</b></p>
          <p>{{locationData.address}}, {{locationData.city}}<br>{{locationData.state}}, {{locationData.zip}}</p>
          <p>{{locationData.phone}}</p>
        </address>
        <span><img src={{locationData.logo}} height="45" width="131"></span>
        </header>
      <article>
        <h1>Recipient</h1>
            <address>
              <p>{{donorData.d_first_name | titlecase}} {{donorData.d_last_name | titlecase}}
                <!-- <br>{{donorData.orgs[0].org_name}} -->
                <br>{{donorData.d_user_email}}<br>
                {{donorData.d_tel}}      </p></address>
        <table class="meta">
          <tbody><tr>
            <th>Date</th>
            <td>{{donorData.added_date | date:'longDate'}} </td>
          </tr>
        </tbody></table>
        <table class="inventory">
          <thead>
            <tr>
              <th width="45%">Item</th>
              <th width="45%">Description</th>
              <th width="10%">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="tdreserv">Donation</td>
              <td class="tdreserv"> {{donorData.campaign.name}}
                <p *ngIf="donorData.d_payment_method == 'CC' ">Credit Card : {{donorData.d_transaction_ref}}</p>
                <!-- <p *ngIf="donorData.payment_method == 'Credit Card' ">Credit Card :{{donorData.credit_card_number}}</p> -->
                <p *ngIf=" donorData.d_payment_method != 'CC'">{{donorData.d_payment_method}}</p>
              </td>
              <td class="tdreserv" > <span style="float: right;"> {{donorData.d_amount | currency}}</span> </td>
            </tr>
          </tbody>
        </table>
        <table class="balance">
          <tbody><tr>
            <th>Total</th>
            <td>{{donorData.d_amount | currency}}</td>
          </tr>
          <tr>
            <th>Amount Paid</th>
            <td>{{donorData.d_amount | currency}}</td>
          </tr>
        </tbody></table>
      </article>
      <!-- <p>{{donationSettingDate.receipt_notes}}</p> -->
      <p [innerHTML]="donationSettingDate.receipt_notes" >
      </p>
        <p></p><p style="text-align: right;"><br></p><p></p>
        </body>
</div>

