import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MemberService } from 'src/app/services/member.service';
import { UserLevelData } from 'src/app/shared/constants/userRoles';
import { States } from 'src/app/shared/states';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { ClassesService } from 'src/app/services/classes.service';
import { LocationService } from 'src/app/services/location.service';
import { ConfirmDeleteDialogComponent } from 'src/app/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { MemberSignupPaymentComponent } from '../member-signup-payment/member-signup-payment.component';
import { ReCaptchaService } from 'src/app/services/re-captcha.service';
declare var grecaptcha: any;

@Component({
  selector: 'app-class-signup',
  templateUrl: './class-signup.component.html',
  styleUrls: ['./class-signup.component.scss']
})
export class ClassSignupComponent implements OnInit {
  userLevel = UserLevelData.userLevels
  membersForm: FormGroup
  dataSource = new MatTableDataSource([]);
  name;
  id: string;
  stateList;
  loading;
  loadingList;
  stateListfiltered;
  uploadedFile;
  access;
  locationsList;
  classesList;
  scheduleList;
  filteredSessions;
  classId;
  classTimesID;
  filteredClasses
  filteredClass
  upcomingClassesList;
  isSessions = false
  classData;
  fee;
  classSessions
  isEmailValid;
  checkMail
  signed = false
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  currentDate = moment(new Date()).format('YYYY-MM-DD')
  personId
  themeColor
  recaptchaToken: string | null = null;
  constructor(
    private memberService: MemberService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private reCaptchaService: ReCaptchaService,
    private toastr: ToastrService,
    private classService: ClassesService,
    private locationService: LocationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.stateList = States.statesList;
    this.stateListfiltered = this.stateList
    this.loading = true;
    this.route
      .queryParams
      .subscribe(params => {
        this.id = params.loc_id
        this.name = params.name
        if (params.tc) {
          this.themeColor = `#${params.tc}`
        } else if (params.c) {
          this.themeColor = params.c
        } else {
          this.themeColor = '#ff0000'
        }
      })
    this.getLocationInfo();
    this.getUpcomingClasses();
    this.confirmDialog()
    // this.getActiveClasses();
    // this.checkMemberId();
    this.membersForm = this.formBuilder.group({
      user_level: [{ value: '0.5', disabled: true }],
      member_type: 'Class Only',
      loc_id: [{ value: '', disabled: true }],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipcode: ['', [Validators.required, Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]],
      tel: ['', [Validators.required, Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)]],
      work_phone: ['', Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)],
      home_phone: ['', Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)],
      user_email: ['', [Validators.required, Validators.email]],
      user_photo: '',
      dateJoined: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      dateExpires: [this.datePipe.transform(new Date(new Date().setFullYear(new Date().getFullYear() + 10)), 'yyyy-MM-dd')],
    })
    this.membersForm.get('loc_id').patchValue(this.name)
    this.membersForm.get('user_level').patchValue('Classes Only')
  }

  // getActiveClasses() {
  //   this.loadingList = true
  //   this.classService.activeClassesList(this.id).subscribe(res => {
  //     this.classesList = res.data.map(e => {
  //       return {
  //         label: e.title,
  //         value: e.classID,
  //         fee: e.memberFee ? e.memberFee : 0,
  //         endDate: e?.class_schedule?.endDate,
  //       }
  //     })
  //     this.filteredClasses = this.classesList
  //     this.loadingList = false
  //   }, err => {
  //     console.error(err)
  //     this.loadingList = false
  //   })
  // }

  getUpcomingClasses() {
    this.loadingList = true
    this.classService.getAllClasses(1, this.id, null, '', 'Active', '', '').subscribe(res => {
      this.upcomingClassesList = res.data.map(e => {
        return e.class_times.map(f => {
          let id = e._id
          return {
            ...f,
            label: e.title,
            value: e.classID,
            classDescription: e.classDescription,
            fee: e.memberFee ? e.memberFee : 0,
            endDate: this.datePipe.transform(f.endDate, 'MMM d, y, h:mm a', 'UTC'),
          }
          // return {
          //   ...f,
          //   _id: e._id,
          //   classStartDate: this.datePipe.transform(f.startDate, 'MMMM d, y, h:mm a', 'UTC'),
          //   classEndDate: this.datePipe.transform(f.endDate, 'MMMM d, y, h:mm a', 'UTC'),
          //   title: e.title,
          //   classDescription: e.classDescription
          // }
        })
      }).flat()
      this.classesList = this.upcomingClassesList.filter(g => new Date(this.datePipe.transform(g.endDate, 'MMMM d, y, h:mm a', 'UTC')) > new Date(this.datePipe.transform(new Date, 'MMMM d, y, h:mm a', 'UTC'))).slice(0, 10)
      const unique2 = this.classesList.filter((obj, index) => {
        return index === this.classesList.findIndex(o => obj.label === o.label && obj.value === o.value);
      });
      if (unique2) {
        this.filteredClasses = unique2
        this.filteredClass = unique2
      }
      this.loadingList = false
    }, err => {
      console.log(err)
      this.loadingList = false
    })
  }

  getClasseSessions(val: MatSelectChange) {
    this.filteredSessions = []
    this.classSessions = undefined
    this.classTimesID = undefined
    this.classId = val
    this.classData = this.upcomingClassesList.filter(g => g.value == val)[0]
    this.fee = this.classData.fee
    this.loadingList = true
    this.classService.getAllSessions(this.id, this.classId.toString(), null, null, null).subscribe(res => {
      this.scheduleList = res.data.map(e => {
        return {
          ...e,
          label: e.instructor.full_name,
          startDate: this.datePipe.transform(e.startDate, 'MMM d, y, h:mm a', 'UTC'),
          endDate: this.datePipe.transform(e.endDate, 'MMM d, y, h:mm a', 'UTC'),
          AvbCount: e.maxParticipants - e.memberCount
        }
      }).filter(g => new Date(g.endDate) > new Date())
      this.filteredSessions = this.scheduleList
      if (!this.filteredSessions && !this.filteredSessions.length)
        this.isSessions = true

      this.loadingList = false
    }, err => {
      console.error(err)
      this.loadingList = false
    })
  }

  getClasseSchedule(val: MatSelectChange) {
    this.classTimesID = val
    this.classSessions = this.scheduleList.filter(g => g.classTimesID == val)[0]
  }

  getmemberavb(sessions) {
    if (sessions) {
      return false
    } else {
      return true
    }
  }

  applyFilterClass(str) {
    this.filteredClass = this.filteredClasses
    this.filteredClass = this.filteredClasses.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(str.toLowerCase());
      });
    });
  }
  applyFilterSessions(str) {
    this.filteredSessions = this.scheduleList
    this.filteredSessions = this.scheduleList.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(str.toLowerCase());
      });
    });
  }

  reLoad() {
    this.stateListfiltered = this.stateList
    this.filteredClass = this.filteredClasses
    this.filteredSessions = this.scheduleList
  }

  applyStateFilter(str) {
    this.stateListfiltered = this.stateList
    this.stateListfiltered = this.stateList.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(str.toLowerCase());
      });
    });
  }

  // checkMemberId() {
  //   this.memberService.getMemberFormId(this.id).subscribe(res => {
  //     if (res && res.statusCode == 200) {
  //       this.personId = res.data.newMemberId
  //     }
  //   }, err => {
  //     console.log(err)
  //   })
  // }

  checkEmail(event: Event) {
    const email = (event.target as HTMLInputElement).value;
    if (email != '') {
      this.memberService.checkEmail(this.id, email).subscribe(res => {
        if (res.status === 'success')
          this.checkMail = `Email-ID is already Taken`
        this.isEmailValid = false;
      }, err => {
        if (err.error.status === 'failure')
          this.checkMail = ``
        this.isEmailValid = true;
      })
    }
  }

  uploadImage(event) {
    this.uploadedFile = event.target.files[0];
    this.membersForm.controls.user_photo.setValue(this.uploadedFile.name)
  }

  getLocationInfo() {
    this.loading = true;
    this.locationService.getLocationDetails(this.name, this.id).subscribe(res => {
      this.locationsList = res.data[0];
      if (this.locationsList.ui_theme == 'Custom') {
        this.themeColor = this.locationsList.ui_primary_clor
      } else if (this.locationsList.ui_theme != 'Custom') {
        this.themeColor = this.locationsList.ui_theme
      } else {
        this.themeColor = '#ff0000'
      }
      if (res.data[0].status == "Active") {
        this.access = true;
      } else {
        this.access = false;
      }
      this.loading = false;
    }, err => {
      this.loading = false;
      console.log(err)
    })
  }

  submit() {
    if (this.isEmailValid) {

      let payload = { ...this.membersForm.value }
      payload.full_name = `${payload.first_name.replace(/ /g, "")} ${payload.last_name}`
      payload.loc_id = this.id
      // payload.personID = this.personId
      payload.user_level = "0.5"
      // let formData = new FormData();
      payload.user_level = JSON.parse(payload.user_level)
      // if (this.uploadedFile) {
      //   delete payload.user_photo
      //   formData.append('user_photo', this.uploadedFile);
      // }
      // for (var key in payload) {
      //   formData.append(key, payload[key]);
      // }
      this.loading = true;
      payload.URL = this.router.url
      this.memberService.memberData = payload
      delete payload.URL
      this.memberService.createMember(this.id, payload).subscribe(res => {
        if (res && res.statusCode == 200) {
          this.toastr.success(res.message);

          let classParticipants = {
            userID: res.data.id,
            classAttendance: '0',
            user_Status: '1',
            certified: '0',
            self_signup: 'Yes'
          }
          let payment = {
            // id: 'memberId',
            amount: this.fee,
            title: this.classData.label,
            classPTimeID: this.classTimesID,
            // classMemId: 'memberId',
            classTId: this.classId
          }
          if (this.fee > 0) {
            this.loading = false;
            // this.makePayment(res.data, payment)
            this.executeRecaptcha('classsignuppage', res.data, payment)

          } else {
            this.classService.addParticipant(this.id, this.classId, this.classTimesID, classParticipants).subscribe(res => {
              if (res) {
                this.loading = false;
                this.toastr.success(res.message)
                this.router.navigate(['/member-sucess'],
                  { queryParams: { type: this.locationsList.can_member_login, memtype: 'class', locName: this.locationsList.name, className: this.classData.label, locPhone: this.locationsList.phone, locEmail: this.locationsList.email } })
              }
            }, err => {
              console.log(err)
              this.loading = false;
              this.toastr.error(err.error.message)
              this.toastr.error('Please Login and Register')
            })
          }
        }
      }, err => {
        this.loading = false;
        this.toastr.error(err.error.message);
        console.log(err)
      })
    }
  }

  executeRecaptcha(action: string, userD, payment) {
    let sKC = sessionStorage.getItem('skc')
    grecaptcha.ready(() => {
      grecaptcha.execute(sKC, { action: action }).then((token: string) => {
        this.recaptchaToken = token;
        let recaptchaTok = {
          token: token
        }
        this.reCaptchaService.reCaptchaVerification(recaptchaTok).subscribe(res => {
          if (res.statusCode == 200) {
            console.log('reCAPTCHA verification successful', res);
            this.makePayment(userD, payment)
          } else {
            console.log('reCAPTCHA verification failed', res);
            this.toastr.error('reCAPTCHA verification failed')
          }
        }, err => {
          console.log(err)
          this.toastr.error(err.error.data[0], 'Error in verifying reCAPTCHA:')
        })
      });
    });
  }

  makePayment(userD, payment) {
    // this.loadingf = false

    const dialogRef = this.dialog.open(MemberSignupPaymentComponent, {
      width: "100vw",
      maxWidth: "1450px",
      minWidth: "450px",
      height: "750px",
      data: {
        data: this.locationsList,
        userData: userD,
        classData: payment
      },
    });
    dialogRef.afterClosed().subscribe(res => {
      this.loading = true;

      let classParticipants = {
        userID: userD.id,
        classAttendance: '0',
        user_Status: '1',
        certified: '0',
        classPayment: '1',
        self_signup: 'Yes'
      }
      console.log(this.id, this.classId, this.classTimesID, classParticipants)
      if (res == 'payment') {
        this.classService.addParticipant(this.id, this.classId, this.classTimesID, classParticipants).subscribe(res => {
          if (res) {
            this.toastr.success(res.message)
            this.loading = false;
            this.router.navigate(['/member-sucess'],
              { queryParams: { type: this.locationsList.can_member_login, memtype: 'class', locName: this.locationsList.name, className: this.classData.label, locPhone: this.locationsList.phone, locEmail: this.locationsList.email } })
          }
        }, err => {
          console.log(err)
          this.loading = false;
          this.toastr.error(err.error.message)
          this.toastr.error('Member is created and Payment is done ,But not Register Please Login and contact location')
        })
      } else {
        this.loading = false;
        this.toastr.success('Member is created you can login and Register');
      }
    })
  }

  confirmDialog() {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: "500px",
      disableClose: true,
      // panelClass: 'tool-bar',
      data: {
        message: 'Do you have an existing account?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        },
        themeColor: this.themeColor
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.router.navigate(['/login'])
      }
    });
  }
  // async signUp(memberId) {
  //   this.classService.getAllParticipants(
  //     this.id,
  //     this.membersForm.controls.class_ID.value.value,
  //     this.membersForm.controls.classTimesID.value.classTimesID, 1,
  //     this.membersForm.controls.classTimesID.value.maxParticipants, null).subscribe(res => {
  //       if (res.data.map(e => e.userID).includes(memberId)) {
  //         this.toastr.error('Your are already member for this class.')
  //         return
  //       }
  //       if (res.data.length == this.membersForm.controls.classTimesID.value.maxParticipants) {
  //         this.toastr.error('Participants for this class is filled, Please contact admin.')
  //         return
  //       }
  //       if (this.membersForm.controls.class_ID.value.fee > 0) {

  //         this.router.navigate(['/members/members-payment'], {
  //           queryParams: {

  //             id: memberId,

  //             amount: this.membersForm.controls.class_ID.value.fee,
  //             title: this.membersForm.controls.class_ID.value.label,
  //             classPTimeID: this.membersForm.controls.classTimesID.value.classTimesID,
  //             self_signup: 'Yes',
  //             classMemId: memberId
  //           }
  //         })
  //       } else {
  //         let classParticipants = {
  //           userID: memberId,
  //           classAttendance: '0',
  //           user_Status: '1',
  //           certified: '0',
  //           self_signup: 'Yes'
  //         }
  //         this.classService.addParticipant(this.id,
  //           this.membersForm.controls.class_ID.value.value,
  //           this.membersForm.controls.classTimesID.value.classTimesID,
  //           classParticipants).subscribe(res => {
  //             if (res) {
  //               this.toastr.success(res.message)
  //               this.router.navigate(['/login'])
  //             }
  //           }, err => {
  //             console.log(err)
  //             this.toastr.success(err.error.message)
  //           })
  //       }
  //     })
  // }

}
