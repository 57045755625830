import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthenticationService } from "src/app/services/authentication.service";
import { PaymentService } from "src/app/services/payment.service";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";

@Component({
    selector: 'paypal-card',
    templateUrl: './paypal.component.html',
    styleUrls: ['./paypal.component.scss']
})

export class PaypalComponent implements OnInit {
    // Input Variables
    @Input() userId
    @Input() loc_id
    @Input() buttonStyles
    @Input() grandTotal
    @Input() itemDetails
    @Input() paymentDesc
    // Output Variables
    @Output() paymentResponse = new EventEmitter<any>()

    // Utility Variables
    months = [
        { value: "01", label: "01 Jan" },
        { value: "02", label: "02 Feb" },
        { value: "03", label: "03 Mar" },
        { value: "04", label: "04 Apr" },
        { value: "05", label: "05 May" },
        { value: "06", label: "06 Jun" },
        { value: "07", label: "07 Jul" },
        { value: "08", label: "08 Aug" },
        { value: "09", label: "09 Sep" },
        { value: "10", label: "10 Oct" },
        { value: "11", label: "11 Nov" },
        { value: "12", label: "12 Dec" }
    ]
    years = this.generateArrayOfYears()
    // Component Variables
    creditCardFormForPaypal: FormGroup
    loading: boolean;


    constructor(
        // @Inject(MAT_DIALOG_DATA) public data,
        private formBuilder: FormBuilder,
        private authService: AuthenticationService,
        private paymentService: PaymentService,
        private toastr: ToastrService,
        private router: Router,
    ) {

    }
    ngOnInit(): void {
        console.log(this.buttonStyles)
        //    if(buttonStyles) 
        this.creditCardFormForPaypal = this.formBuilder.group({
            cardholdername: ['', Validators.required],
            credit_card_number: ['', [Validators.required, Validators.pattern(/^[0-9]{16}$/)]],
            card_security_code: ['', Validators.required],
            card_exp_month: ['', Validators.required],
            card_exp_yr: ['', Validators.required],
            Notes: ''
        });
    }


    //Utility Methods
    generateArrayOfYears() {
        var max = new Date().getFullYear()
        var min = max + 14
        var years = []
        for (var i = max; i <= min; i++) {
            years.push(i.toString())
        }
        return years
    }

    // Card Methods
    validateCode(event) {
        let patt = /^([0-9])$/;
        let result = patt.test(event.key)
        return event.target.value.length <= 2 ? result : false;
    }
    validateCardNumber(event) {
        let patt = /^([0-9])$/;
        let result = patt.test(event.key)
        return event.target.value.length <= 15 ? result : false;
    }

    // Component Methods i.e actual functions of component, service calls
    payForCreditCardWithPaypal() {
        this.loading = true
        let payload = {
            "loc_id": this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id,
            "AMT": this.grandTotal,
            "ACCT": this.creditCardFormForPaypal.controls.credit_card_number.value,
            "EXPDATE": this.creditCardFormForPaypal.controls.card_exp_month.value +
                this.creditCardFormForPaypal.controls.card_exp_yr.value.substring(2),
            "CVV2": this.creditCardFormForPaypal.controls.card_security_code.value,
            itemDetails: this.itemDetails,
            payment_desc: this.paymentDesc ? this.paymentDesc : this.itemDetails.payment_desc
        }
        if (this.itemDetails.purchaseItems) {
            if (this.itemDetails.purchaseItems[0]['key']) {
                payload.itemDetails.purchaseItems[0]['campaignId'] = this.userId
                payload = {
                    ...payload,
                    ...payload.itemDetails.purchaseItems[0]['donationData']
                }
                delete payload.itemDetails.purchaseItems[0]['key']
                delete payload.itemDetails.purchaseItems[0]['donationData']
            } else {
                payload['userId'] = this.userId
            }
        } else {
            payload['userId'] = this.userId
        }
        if (this.itemDetails.purchaseItems) {
            if (this.itemDetails.purchaseItems[0].campaignId) {
                this.paymentService.payDonationPaypal(this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id, payload).subscribe(res => {
                    if (res) {
                        this.toastr.success(res.message);
                        this.paymentResponse.emit({ status: true, response: res.data })
                    }
                }, err => {
                    console.log(err)
                    this.toastr.error(err.error.message);
                    this.loading = false
                })
            } else {
                this.paymentService.payPaypal(this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id, payload).subscribe(res => {
                    if (res) {
                        this.toastr.success(res.message);
                        this.paymentResponse.emit({ status: true, response: res.data })
                    }
                }, err => {
                    console.log(err)
                    this.toastr.error(err.error.message);
                    this.loading = false
                })
            }
        } else {
            this.paymentService.payPaypal(this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id, payload).subscribe(res => {
                if (res) {
                    this.toastr.success(res.message);
                    this.paymentResponse.emit({ status: true, response: res.data })
                }
            }, err => {
                console.log(err)
                this.toastr.error(err.error.message);
                this.loading = false
            })
        }

    }
}